<script setup>
  import CategoryIcon from "@/components/CategoryIcon.vue";

  const props = defineProps({ blok: Object });

  import Date from "@/components/Date.vue";

  import { computed, ref } from "vue";
  import Authors from "@/components/Authors.vue";

  const inViewLeft = ref(false);
  function onIntersectLeft(isIntersecting) {
    inViewLeft.value = isIntersecting;
  }

  const inViewRight = ref(false);
  function onIntersectRight(isIntersecting) {
    inViewRight.value = isIntersecting;
  }

  const srcSetLandscape = computed(() => {
    let image = props.blok.stories[0].content.header[0].background_image;
    let focus = props.blok.stories[0].content.header[0].background_image.focus;
    let brightness = props.blok.stories[0].content.header[0].background_brightness;
    let blur = props.blok.stories[0].content.header[0].background_blur;

    return (
      image.filename +
      "/m/592x333/filters:focal(" +
      focus.value +
      "):brightness(" +
      brightness.value +
      "):blur(" +
      blur.value +
      ") 592w," +
      image.filename +
      "/m/960x540/filters:focal(" +
      focus.value +
      "):brightness(" +
      brightness.value +
      "):blur(" +
      blur.value +
      ") 960w," +
      image.filename +
      "/m/704x396/filters:focal(" +
      focus.value +
      "):brightness(" +
      brightness.value +
      "):blur(" +
      blur.value +
      ") 704w," +
      image.filename +
      "/m/880x495/filters:focal(" +
      focus.value +
      "):brightness(" +
      brightness.value +
      "):blur(" +
      blur.value +
      ") 880w"
    );
  });

  const srcSetPortrait = computed(() => {

    let image = props.blok.stories[1].content.header[0].background_image;
    let focus = props.blok.stories[1].content.header[0].background_image.focus;
    let brightness = props.blok.stories[1].content.header[0].background_brightness;
    let blur = props.blok.stories[1].content.header[0].background_blur;

    if (props.blok.stories[1].content.header[0].background_image_mobile) {
      image = props.blok.stories[1].content.header[0].background_image_mobile;
      focus = 'undefined';
      brightness = 'undefined';
      blur = 'undefined';
    }

    return (
      image.filename +
      "/m/600x800/filters:focal(" +
      focus.value +
      "):brightness(" +
      brightness.value +
      "):blur(" +
      blur.value +
      ") 600w," +
      image.filename +
      "/m/960x1280/filters:focal(" +
      focus.value +
      "):brightness(" +
      brightness.value +
      "):blur(" +
      blur.value +
      ") 960w," +
      image.filename +
      "/m/426x568/filters:focal(" +
      focus.value +
      "):brightness(" +
      brightness.value +
      "):blur(" +
      blur.value +
      ") 426w" +
      image.filename +
      "/m/465x620/filters:focal(" +
      focus.value +
      "):brightness(" +
      brightness.value +
      "):blur(" +
      blur.value +
      ") 465w" +
      image.filename +
      "/m/531x708/filters:focal(" +
      focus.value +
      "):brightness(" +
      brightness.value +
      "):blur(" +
      blur.value +
      ") 531w"
    );
  });
</script>

<template>
  <section class="hp-section">
    <v-container
      fluid
      class="two-columns d-md-flex pl-0 pr-0 pt-6 pb-6 mt-0 mb-0"
    >
      <v-row no-gutter>
        <v-col
          cols="12"
          md="6"
        >
          <router-link :to="{ name: 'article', params: { slug: blok.stories[0].slug } }">
            <v-row class="h-100">
              <v-col cols="12">
                <div class="d-flex flex-column justify-space-between h-100 pb-0">
                  <div class="mt-0">
                    <v-img
                      v-intersect="onIntersectLeft"
                      class="slide-in-left"
                      v-bind:class="{ 'slide-in-left in-view': inViewLeft }"
                      aspect-ratio="16/9"
                      cover
                      :srcset="srcSetLandscape"
                      sizes="(max-width: 600px) 592px, (min-width: 600px) 960px, (min-width: 960px) 704px, (min-width: 1280px) 880px"
                      :src="
                        blok.stories[0].content.header[0].background_image.filename +
                        '/m/880x495/filters:focal(' +
                        blok.stories[0].content.header[0].background_image.focus +
                        ')'
                      "
                      lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                      :alt="blok.stories[0].content.header[0].background_image.alt"
                    ></v-img>
                  </div>
                  <div class="d-flex flex-column justify-start fill-height pt-4 pt-md-6 pt-md-12">
                    <div class="d-flex flex-row meta">
                      <category-icon
                        v-if="blok.stories[0].content.category"
                        :category="blok.stories[0].content.category"
                        class="mr-4"
                      />
                      <authors :reporters="blok.stories[0].content.reporters" />
                    </div>
                    <h2 class="heading-highlight smaller-title-section right-border mb-0 mt-4 mt-md-6 mt-md-11">
                      <span>{{ blok.stories[0].name }}</span>
                    </h2>
                  </div>
                </div>
              </v-col>
            </v-row>
          </router-link>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class=""
        >
          <router-link :to="{ name: 'article', params: { slug: blok.stories[1].slug } }">
            <v-row no-gutter>
              <v-col
                cols="12"
                md="3"
                lg="4"
              >
                <div class="middle-border"></div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="8"
              >
                <div
                  v-intersect="onIntersectRight"
                  class="slide-in-right"
                  v-bind:class="{ 'slide-in-right in-view': inViewRight }"
                >
                  <div class="story-overlay text-white story-two">
                    <div class="story-overlay--content pa-6">
                      <div class="d-flex flex-row mb-4">
                        <category-icon
                          v-if="blok.stories[1].content.category"
                          :category="blok.stories[1].content.category"
                          colour="#ffffff"
                          class="mr-4"
                        />
                        <authors :reporters="blok.stories[1].content.reporters" />
                      </div>
                      <div class="text-white">
                        <h2 class="departure-mono-regular story-overlay--title mb-0 mb-md-6">
                          {{ blok.stories[1].name }}
                        </h2>
                        <p class="d-none d-md-block saans-mono-regular story-overlay--standfirst">
                          {{ blok.stories[1].content.header[0].standfirst }}
                        </p>
                      </div>
                    </div>
                    <v-img
                      aspect-ratio="3/4"
                      cover
                      :srcset="srcSetPortrait"
                      sizes="(max-width: 600px) 600px, (min-width: 600px) 960px, (min-width: 960px) 426px, (min-width: 1280px) 465px, 531px"
                      :src="
                        blok.stories[1].content.header[0].background_image.filename +
                        '/m/600x800/filters:focal(' +
                        blok.stories[1].content.header[0].background_image.focus +
                        ')'
                      "
                      lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                      :alt="blok.stories[1].content.header[0].background_image.alt"
                      class="mt-0 mt-md-0"
                      ref="targetTwo"
                    ></v-img>
                  </div>
                </div>
              </v-col>
            </v-row>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<style scoped lang="scss">
  .heading-highlight {
    @media (width >= 960px) {
      width: 80%;
    }
  }
  .meta {
    @media (width < 960px) {
      margin-left: 2px;
    }
  }
  .story-two {
    margin-top: 0;
    @media (width >= 600px) {
      margin-top: 120px;
    }
  }
</style>
