<script setup>

import moment from "moment";

const props = defineProps({
  date: {
    type: String,
    required: true,
  },
  invert: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    default: 'black'
  }
});
</script>

<template>
  <div
      class="article-meta d-flex justify-start flex-wrap align-center"
  >
  <div v-if="date" class="article-date" :style="'color: ' + props.color" :class="{invert: invert}">
      <span>
        {{ moment(date).format("MMMM D YYYY") }}
      </span>
  </div>
  </div>
</template>

<style scoped lang="scss">
.article-date {
  line-height: 21px;
  height: 21px;

  span {
    font-family: "SaansMonoRegular", sans-serif;
    font-size: 0.813rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }

  &.invert {
    filter: invert(1);
  }
}
</style>
