<script setup lang="ts">
  import { Position } from "@unovis/ts";
  import { VisAxis, VisCrosshair, VisStackedBar, VisTooltip, VisXYContainer } from "@unovis/vue";
  import { StackedBar } from "@unovis/ts";
  import { data, labels, DataRecord, FormatConfig, colors, capitalize } from "./data.ts";

  const height = 450;
  const x = (d: DataRecord) => d.Year;
  const y = ["B2B", "Education", "Fintech", "Consumer", "Healthcare", "Other"].map(
    (category) => (d: DataRecord) => d[category]?.percent || 0
  );

  function getIcon(f: FormatConfig): string {
    return `<span style="border-radius:100%;width:16px;height:16px;background-color:${f.color};display:inline-block;margin-right:.5rem;"></span>${f.label}\t`;
  }

  function tooltipTemplate(d: DataRecord): string {
    const categories = ["B2B", "Education", "Fintech", "Consumer", "Healthcare", "Other"];
    const dataLegend = categories
      .filter((category) => d[category]?.number > 0)
      .map(
        (category, i) =>
          `<div style="display:flex;align-items:center;margin:0;padding:0; line-height:0;margin-bottom:.8rem;">
                <span style="border-radius:100%;width:16px;height:16px;background-color:${labels[i].color};display:inline;margin-right:.5rem;"></span>
                <span>${category} ${d[category].number}</span>
            </div>`
      )
      .join("");

    return `<div ><b style="margin-bottom:1rem;display:block;">${d.Year}</b><div>${dataLegend}</div></div>`;
  }

  const items = Object.entries(colors).map(([n, c]) => ({
    name: capitalize(n),
    color: c,
  }));

  const color = (d: labels, i: number) => items[i].color;
</script>

<template>
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css"
  />
  <h3 class="mb-6">How has Y Combinator’s sector focus changed over time?</h3>
  <div class="mb-6">
    <span
      v-for="l in labels"
      style="margin-right: 20px"
      v-html="getIcon(l)"
    ></span>
  </div>
  <VisXYContainer
    :data="data"
    :height="height"
  >
    <VisStackedBar
      :x="x"
      :y="y"
      :roundedCorners="false"
      :tooltipTemplate="tooltipTemplate"
      :position="Position.Diverging"
      :format="labels"
      :color="color"
      :stackOrder="['ascending']"
    />
    <VisCrosshair
      :template="tooltipTemplate"
      :color="color"
    />
    <VisTooltip :verticalShift="height" />
    <VisAxis type="x" />
    <VisAxis
      type="y"
      :tickFormat="(v) => `${v}%`"
    />
  </VisXYContainer>
</template>

<style scoped>
  .chart-tooltip {
    display: flex;
    flex-direction: column;
  }
</style>
