const svgModules = import.meta.glob("@/assets/logos/*.svg", { eager: true });

const svgFiles = (number) => {
  const svgArray = Object.entries(svgModules).map(([path, module]) => ({
    path,
    url: module.default,
    name: path.split("/").pop().replace(".svg", ""),
  }));

  return svgArray.find((svg) => svg.name.includes(number.toString()));
};

export function useTimeline() {
  const events = [
    {
      year: "2005",
      logos: [svgFiles(1)],
      amounts: [32],
    },
    {
      text: "Y Combinator was founded by Paul Graham, Jessica Livingston, Robert Morris and Trevor Blackwell and launched with the Summer Founders Program",
      textMobile: "Y Combinator was founded by Paul Graham, Jessica Livingston, Robert Morris and Trevor Blackwell",
    },
    {
      text: "The first batch had just eight companies, including Simmery, Memamp, Loopt (co-founded by Sam Altman) and Reddit, its fourth highest-valued company",
      textMobile: "The first batch had just eight companies, including Reddit and Sam Altman’s Loopt",
    },
    {
      year: "2007",
      logos: [svgFiles(2)],
      amounts: [9],
    },
    {
      year: "2009",
      logos: [svgFiles(32), svgFiles(31)],
      amounts: [70, 85],
    },
    {
      text: "Under Paul Graham’s leadership, Y Combinator invested in some of its most successful startups, including Airbnb (W09) and Stripe (S09)",
      textMobile: "Under Paul Graham, Y Combinator invested in some of its most successful startups",
    },
    {
      year: "2011",
    },
    {
      text: "Sam Altman joined the accelerator as a part-time partner in 2011 and went on to become president in 2014",
      textMobile: "Sam Altman joined part-time in 2011 and became president in 2014",
    },
    {
      year: "2012",
      logos: [svgFiles(44), svgFiles(43), svgFiles(42), svgFiles(41)],
      amounts: [6, 10, 11.3, 72],
    },
    {
      year: "2013",
      logos: [svgFiles(52), svgFiles(51)],
      amounts: [5.6, 73],
    },
    {
      year: "2014",
      logos: [svgFiles(6)],
      amounts: [8],
    },
    {
      text: "Since 2005, Y Combinator has funded more than 5,000 companies and worked with over 7,000 founders. ",
      textMobile: "Since 2005, Y Combinator has funded more than 5,000 companies",
    },
    {
      year: "2015",
      logos: [svgFiles(72), svgFiles(71)],
      amounts: [7.5, 10],
    },
    {
      year: "2016",
      logos: [svgFiles(82), svgFiles(81)],
      amounts: [5.2, 13.8],
    },
    {
      text: "Under Altman, Y Combinator’s portfolio diversified, producing some of its biggest successes. The accelerator scaled significantly and batches got much bigger",
      textMobile: "Under Altman, Y Combinator’s portfolio diversified, it scaled significantly and batches grew",
    },
    {
      year: "2017",
      logos: [svgFiles(93), svgFiles(91), svgFiles(92)],
      amounts: [12.3, 13.4, 12.6],
    },
    {
      year: "2019",
      logos: [svgFiles(94)],
      amounts: [12],
    },
    {
      year: "2020",
    },
    {
      text: "Geoff Ralstan led Y Combinator through the pandemic. With the whole programme going remote, batches grew again and more international founders participated",
      textMobile: "Geoff Ralstan led Y Combinator through the pandemic and the whole programme went remote",
    },
    {
      year: "2021",
      logos: [svgFiles(95)],
      amounts: [5],
    },
    {
      year: "2023",
    },
    {
      text: "Garry Tan became president in 2023. He has emphasised Y Combinator’s core mission, returning to the early vision of the accelerator",
      textMobile: "Garry Tan became president in 2023, switching focus back to the early vision of the accelerator",
    },
    {
      year: "2025",
    },
    {
      text: "Y Combinator now has more than 400 companies valued at over $100m and more than 90 companies valued over $1bn",
      textMobile: "Y Combinator now has more than 400 companies valued at over $100m and more than 90 unicorns",
    },
  ];

  return { events };
}
