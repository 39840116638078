import { defineStore } from "pinia";
import { inject } from "vue";
import axios from "axios";
import router from "@/router";

export const useAccountStore = defineStore("account", {
  state: () => ({
    authenticated: false,
    user: {},
    lastStory: null,
    price: null,
    promoCode: null,
    subscriberStatus: "anon",
    registrationStepOneCompleted: true,
    registrationStepTwoCompleted: false,
  }),
  persist: true,
  getters: {
  },
  actions: {
    loggedIn(user) {
      this.authenticated = true;
      this.user = user;
      if (user.subscribed) {
        this.subscriberStatus = "paid";
      } else {
        this.subscriberStatus = "lgdin";
      }
    },
    logout() {
      this.authenticated = false;
      this.user = {};
    },
    register(name, email, password) {},
    updateUser(user) {
      this.user = user;
      if (user.subscribed) {
        this.subscriberStatus = "paid";
      }
    },
    updateUserVerified(date) {
      this.user.email_verified_at = date;
    },
    setLastStory(slug) {
      this.lastStory = slug;
    },
    setPrice(price) {
      this.price = price;
      this.promoCode = null;
    },
    clearPrice() {
      this.price = null;
    },
    applyPromoCode(promoCode) {
      this.promoCode = promoCode;
    },
    clearPromoCode() {
      this.promoCode = null;
    },
    setStepOneCompleted() {
      this.registrationStepOneCompleted = true;
    },
    setStepTwoCompleted() {
      this.registrationStepTwoCompleted = true;
    },
  },
});
