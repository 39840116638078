<script setup>
  import { ref } from "vue";
  import PageHeader from "@/components/Common/PageHeader.vue";
  import Foot from "@/components/Foot.vue";
  import TimelineText from "@/components/Timeline/Text.vue";
  import TimelineTextTwo from "@/components/Timeline/TextTwo.vue";
  import TimelineDesktop from "@/components/Timeline/Desktop.vue";
  import TimelineMobile from "@/components/Timeline/Mobile.vue";
  import { useDisplay } from "vuetify";
  const { lgAndUp } = useDisplay();

  const isMobile = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  };
</script>

<template>
  <div class="content-page">
    <v-container
      fluid
      class="outer-container mb-10"
    >
      <v-row>
        <v-col
          cols="12"
          md="5"
          class="position-relative"
        >
          <div
            v-if="lgAndUp"
            style="height: 20vh"
          ></div>
          <!-- add mobile version -->
          <TimelineDesktop
            v-if="lgAndUp"
            :class="isMobile() ? 'mobile' : 'desktop'"
          />
        </v-col>
        <v-col
          cols="12"
          md="7"
        >
          <div class="body-text drop-cap text-left">
            <TimelineText />
          </div>
        </v-col>
      </v-row>

      <TimelineTextTwo />
    </v-container>
  </div>
</template>
