<script setup>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from "swiper/vue";
  // import function to register Swiper custom elements
  import { register } from "swiper/element/bundle";
  import { useTimeline } from "@/composables/useTimeline";
  const { events } = useTimeline();

  // register Swiper custom elements
  register();
  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/pagination";
  import "swiper/css/scrollbar";

  const modules = [FreeMode, Pagination];

  // import required modules
  import { FreeMode, Pagination } from "swiper/modules";

  // Add this ref for tracking swiper position
  import { ref, onMounted } from "vue";
  const isEnd = ref(false);
  const swiperInstance = ref(null);

  const onSwiper = (swiper) => {
    swiperInstance.value = swiper;
  };

  const handleReachEnd = () => {
    isEnd.value = true;
  };

  const handleFromEnd = () => {
    isEnd.value = false;
  };
</script>

<template>
  <div class="mb-10">
    <swiper
      :slidesPerView="1.8"
      :spaceBetween="0"
      :freeMode="true"
      :scrollbar="{ draggable: true }"
      :modules="modules"
      class="mySwiper"
      @swiper="onSwiper"
      @reachEnd="handleReachEnd"
      @fromEdge="handleFromEnd"
    >
      <swiper-slide style="align-items: center; justify-content: center; display: flex">
        <div class="box box--with-echo">
          <div class="box__content">Y Combinator’s top 20 most valuable companies</div>
        </div>
      </swiper-slide>

      <swiper-slide v-for="(event, i) in events">
        <div class="content-holder">
          <div
            class="logo justify-end"
            :class="event.logos.length > 2 ? 'logo--grid' : 'd-flex'"
            v-if="event.logos"
          >
            <div
              class="logo--grid__container"
              v-for="(logo, i) in event.logos"
            >
              <img
                :src="logo.url"
                :alt="logo.name"
                class="w-full"
                :class="`logo-${logo.name}`"
              />
              <p v-if="event.amounts">${{ event?.amounts[i] }}bn</p>
            </div>
          </div>
          <div
            v-else
            class="no-logo"
          ></div>
          <div class="line-h"></div>
          <div class="event-line"></div>
          <p>{{ event?.year ? event.year : event.textMobile }}</p>
        </div>
      </swiper-slide>
    </swiper>
    <div
      class="pt-2 mb-16 top-auto scroll-to-view"
      v-show="!isEnd"
    >
      <i>Swipe for timeline ></i>
    </div>
  </div>
</template>

<style lang="scss">
  .mySwiper {
    .content-holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .swiper-scrollbar-drag {
      background: #f26522;
    }
    .line-h {
      transition: height 0.3s ease-in-out;
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: #f26522;
      position: relative;
      left: 0;
      top: 0;
    }
    .logo,
    .no-logo {
      height: 200px;
      margin-bottom: 10px;
    }
    .swiper-slide {
      min-height: 500px;

      p {
        color: #f26522;
        position: relative;
        font-size: 12px !important;
        line-height: 1.2;
        top: 0;
        border: 1px solid #f26522;
        border-radius: 5px;
        padding: 0.4rem;
        margin: 0 1rem;
        display: inline-block;
      }

      .event-line {
        transition: height 0.3s ease-in-out;
        content: "";
        display: block;
        width: 1px;
        height: 20px;
        background: #f26522;
        position: relative;

        top: 0;
      }
    }
    .swiper-slide-active {
      .event-line {
        height: 50px;
      }
    }

    .logo {
      flex-direction: column;
      align-items: center;
      gap: calc(0.25rem * 1);
      // padding-right: 30px;
      text-align: center;

      img {
        max-height: 50px;
        max-width: 80px;
        min-height: 40px;
        // min-width: 130px;
      }

      p {
        text-align: center;
        background-color: #f26522;
        display: inline-flex;
        padding: 0.2rem 0.4rem;
        width: auto;
        color: white;
        font-weight: bold;
        justify-content: center;
      }

      &:last-of-type {
        margin-bottom: 10px;
      }
    }

    .logo--grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0.2rem;
      grid-row-gap: 0.2rem;
      justify-items: center;
      align-items: center;

      &__container {
        display: flex;
        // height: 100%;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
      }

      img {
        max-height: 40px;
      }
    }
  }

  .box {
    width: 200px;
    position: absolute;
    z-index: 100;
    position: relative;
    z-index: 1;
    z-index: 1;
    // font-size: 0.6rem;

    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #f26522;
      border-radius: 5px;
      color: #f26522;
      font-size: 12px;
      background-color: #f4f2ee;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 5;
      padding: 10px;
    }

    &--with-echo {
      top: -40px;
      z-index: 20;
      margin-left: 10px;
      margin-right: 21px;
      &:after {
        content: "";
        border: 1px solid #f26522;
        border-radius: 5px;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: -4px;
        left: -4px;
      }
    }
  }
  .scroll-to-view {
    color: #f26522;
  }
</style>
