<script setup>
  const props = defineProps({
    credit: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: false,
    }
  });
</script>

<template>
  <p v-if="props.credit && props.url" class="mt-0 illustration-credit"><a :href="props.url" target="_blank">{{ props.credit }}</a></p>
  <p v-else-if="props.credit" class="mt-0 illustration-credit">{{ props.credit }}</p>
</template>

<style
    scoped
    lang="scss"
>
  .illustration-credit {
    font-family: "SaansMonoRegular", sans-serif;
    font-size: 0.7rem;
    a {
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }
</style>
