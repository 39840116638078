<template>
  <v-container fluid class="pb-0">
    <v-row>
      <v-col v-if="props.centralText" class="v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-7 offset-md-5 v-col-lg-6 offset-lg-3 mt-0 mb-6 pb-0">
        <div class="d-flex justify-center d-md-block" v-html="blok.embed_blockquote">

        </div>
      </v-col>
      <v-col v-else class="v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-7 offset-md-5 mt-0 mb-6 pb-0">
        <div class="d-flex justify-center d-md-block" v-html="blok.embed_blockquote">

        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>

const props = defineProps({ blok: Object, centralText: Boolean })

</script>

<style>
iframe {
  border: none;
}
</style>
