export enum Format {
  B2B = "b2b",
  Education = "education",
  Fintech = "fintech",
  Consumer = "consumer",
  Healthcare = "healthcare",
  Other = "other",
}

export type DataRecord = Record<Format, number> & { Year: number };

export type FormatConfig = {
  format: Format;
  color: string;
  icon: string;
  label: string;
};

export const capitalize = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const colors = {
  B2B: "#151515",
  Education: "#FF8B00",
  Fintech: "#7c3100",
  Consumer: "#f26522",
  Healthcare: "#f9e2a7",
  Other: "#6e6e6e",
};

export const labels: FormatConfig[] = [
  {
    format: Format.B2B,
    color: "#151515",
    icon: "1-circle",
    label: "B2B",
  },
  {
    format: Format.Education,
    color: "#FF8B00",
    icon: "2-circle-fill",
    label: "Education",
  },
  {
    format: Format.Fintech,
    color: "#7c3100",
    icon: "3-circle-fill",
    label: "Fintech",
  },
  {
    format: Format.Consumer,
    color: "#f26522",
    icon: "4-circle-fill",
    label: "Consumer",
  },
  {
    format: Format.Healthcare,
    color: "#f9e2a7",
    icon: "5-circle-fill",
    label: "Healthcare",
  },
  {
    format: Format.Other,
    color: "#6e6e6e",
    icon: "9-circle-fill",
    label: "Other",
  },
];

export const data = [
  {
    Year: 2005,
    B2B: { number: 4, percent: 50 },
    Education: { number: 0, percent: 0 },
    Fintech: { number: 1, percent: 12.5 },
    Consumer: { number: 3, percent: 37.5 },
    Healthcare: { number: 0, percent: 0 },
    Other: { number: 0, percent: 0 },
  },
  {
    Year: 2006,
    B2B: { number: 6, percent: 33.33333333 },
    Education: { number: 0, percent: 0 },
    Fintech: { number: 0, percent: 0 },
    Consumer: { number: 12, percent: 66.66666667 },
    Healthcare: { number: 0, percent: 0 },
    Other: { number: 0, percent: 0 },
  },
  {
    Year: 2007,
    B2B: { number: 10, percent: 31.25 },
    Education: { number: 0, percent: 0 },
    Fintech: { number: 1, percent: 3.125 },
    Consumer: { number: 19, percent: 59.375 },
    Healthcare: { number: 0, percent: 0 },
    Other: { number: 2, percent: 6.25 },
  },
  {
    Year: 2008,
    B2B: { number: 20, percent: 46.51162791 },
    Education: { number: 0, percent: 0 },
    Fintech: { number: 1, percent: 2.325581395 },
    Consumer: { number: 22, percent: 51.1627907 },
    Healthcare: { number: 0, percent: 0 },
    Other: { number: 0, percent: 0 },
  },
  {
    Year: 2009,
    B2B: { number: 14, percent: 33.33333333 },
    Education: { number: 1, percent: 2.380952381 },
    Fintech: { number: 2, percent: 4.761904762 },
    Consumer: { number: 21, percent: 50 },
    Healthcare: { number: 1, percent: 2.380952381 },
    Other: { number: 3, percent: 7.142857143 },
  },
  {
    Year: 2010,
    B2B: { number: 33, percent: 52.38095238 },
    Education: { number: 1, percent: 1.587301587 },
    Fintech: { number: 2, percent: 3.174603175 },
    Consumer: { number: 25, percent: 39.68253968 },
    Healthcare: { number: 0, percent: 0 },
    Other: { number: 2, percent: 3.174603175 },
  },
  {
    Year: 2011,
    B2B: { number: 53, percent: 50.47619048 },
    Education: { number: 5, percent: 4.761904762 },
    Fintech: { number: 7, percent: 6.666666667 },
    Consumer: { number: 32, percent: 30.47619048 },
    Healthcare: { number: 5, percent: 4.761904762 },
    Other: { number: 3, percent: 2.857142857 },
  },
  {
    Year: 2012,
    B2B: { number: 74, percent: 49.66442953 },
    Education: { number: 7, percent: 4.697986577 },
    Fintech: { number: 8, percent: 5.369127517 },
    Consumer: { number: 47, percent: 31.54362416 },
    Healthcare: { number: 6, percent: 4.026845638 },
    Other: { number: 7, percent: 4.697986577 },
  },
  {
    Year: 2013,
    B2B: { number: 51, percent: 52.04081633 },
    Education: { number: 2, percent: 2.040816327 },
    Fintech: { number: 6, percent: 6.12244898 },
    Consumer: { number: 27, percent: 27.55102041 },
    Healthcare: { number: 10, percent: 10.20408163 },
    Other: { number: 2, percent: 2.040816327 },
  },
  {
    Year: 2014,
    B2B: { number: 66, percent: 42.85714286 },
    Education: { number: 2, percent: 1.298701299 },
    Fintech: { number: 14, percent: 9.090909091 },
    Consumer: { number: 40, percent: 25.97402597 },
    Healthcare: { number: 11, percent: 7.142857143 },
    Other: { number: 21, percent: 13.63636364 },
  },
  {
    Year: 2015,
    B2B: { number: 84, percent: 38.88888889 },
    Education: { number: 4, percent: 1.851851852 },
    Fintech: { number: 18, percent: 8.333333333 },
    Consumer: { number: 48, percent: 22.22222222 },
    Healthcare: { number: 31, percent: 14.35185185 },
    Other: { number: 31, percent: 14.35185185 },
  },
  {
    Year: 2016,
    B2B: { number: 83, percent: 37.05357143 },
    Education: { number: 6, percent: 2.678571429 },
    Fintech: { number: 16, percent: 7.142857143 },
    Consumer: { number: 54, percent: 24.10714286 },
    Healthcare: { number: 30, percent: 13.39285714 },
    Other: { number: 35, percent: 15.625 },
  },
  {
    Year: 2017,
    B2B: { number: 69, percent: 28.63070539 },
    Education: { number: 8, percent: 3.319502075 },
    Fintech: { number: 26, percent: 10.78838174 },
    Consumer: { number: 53, percent: 21.99170124 },
    Healthcare: { number: 38, percent: 15.76763485 },
    Other: { number: 47, percent: 19.50207469 },
  },
  {
    Year: 2018,
    B2B: { number: 101, percent: 36.33093525 },
    Education: { number: 8, percent: 2.877697842 },
    Fintech: { number: 25, percent: 8.992805755 },
    Consumer: { number: 55, percent: 19.78417266 },
    Healthcare: { number: 63, percent: 22.6618705 },
    Other: { number: 26, percent: 9.352517986 },
  },
  {
    Year: 2019,
    B2B: { number: 146, percent: 39.67391304 },
    Education: { number: 12, percent: 3.260869565 },
    Fintech: { number: 47, percent: 12.77173913 },
    Consumer: { number: 66, percent: 17.93478261 },
    Healthcare: { number: 49, percent: 13.31521739 },
    Other: { number: 48, percent: 13.04347826 },
  },
  {
    Year: 2020,
    B2B: { number: 207, percent: 47.36842105 },
    Education: { number: 10, percent: 2.288329519 },
    Fintech: { number: 57, percent: 13.04347826 },
    Consumer: { number: 55, percent: 12.58581236 },
    Healthcare: { number: 69, percent: 15.78947368 },
    Other: { number: 39, percent: 8.924485126 },
  },
  {
    Year: 2021,
    B2B: { number: 337, percent: 46.35488308 },
    Education: { number: 28, percent: 3.851444292 },
    Fintech: { number: 116, percent: 15.95598349 },
    Consumer: { number: 83, percent: 11.41678129 },
    Healthcare: { number: 107, percent: 14.71801926 },
    Other: { number: 56, percent: 7.702888583 },
  },
  {
    Year: 2022,
    B2B: { number: 301, percent: 47.4015748 },
    Education: { number: 9, percent: 1.417322835 },
    Fintech: { number: 133, percent: 20.94488189 },
    Consumer: { number: 63, percent: 9.921259843 },
    Healthcare: { number: 68, percent: 10.70866142 },
    Other: { number: 61, percent: 9.606299213 },
  },
  {
    Year: 2023,
    B2B: { number: 336, percent: 68.01619433 },
    Education: { number: 6, percent: 1.214574899 },
    Fintech: { number: 48, percent: 9.71659919 },
    Consumer: { number: 33, percent: 6.680161943 },
    Healthcare: { number: 50, percent: 10.12145749 },
    Other: { number: 21, percent: 4.251012146 },
  },
  {
    Year: 2024,
    B2B: { number: 384, percent: 64 },
    Education: { number: 6, percent: 1 },
    Fintech: { number: 39, percent: 6.5 },
    Consumer: { number: 48, percent: 8 },
    Healthcare: { number: 61, percent: 10.16666667 },
    Other: { number: 62, percent: 10.33333333 },
  },
  {
    Year: 2025,
    B2B: { number: 99, percent: 60.36585366 },
    Education: { number: 6, percent: 3.658536585 },
    Fintech: { number: 10, percent: 6.097560976 },
    Consumer: { number: 12, percent: 7.317073171 },
    Healthcare: { number: 12, percent: 7.317073171 },
    Other: { number: 25, percent: 15.24390244 },
  },
];

export const dataPie = [
  {
    key: "Education",
    value: 2,
  },
  {
    key: "Other",
    value: 10,
  },
  {
    key: "Fintech",
    value: 11,
  },
  {
    key: "Healthcare",
    value: 12,
  },
  {
    key: "Consumer",
    value: 16,
  },
  {
    key: "B2B",
    value: 49,
  },
];
