<script setup>

const props = defineProps({ blok: Object, centralText: Boolean })

import {onMounted, ref} from "vue";

import {useHead} from '@unhead/vue'

const showPost = ref(false)

onMounted(() => {
  console.log("mounted")
  useHead({
    script: [
      // Load the script
      {
        src: 'https://www.tiktok.com/embed.js',
        onload: (el) => {
          console.log("loaded")
          showPost.value = true
        }
      }
    ]
  })
})

</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col v-if="props.centralText" class="v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-8 offset-md-2 v-col-lg-6 offset-lg-3 mt-0 mb-6 pb-0">
        <div style="left: 0; width: 325px; position: relative; margin-left: auto; margin-right: auto">
          <div v-if="showPost" class="d-flex justify-center" v-html="blok.embed_blockquote">

          </div>
        </div>
      </v-col>
      <v-col v-else class="v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-7 offset-md-5 mt-0 mb-6 pb-0">
        <div style="left: 0; width: 325px; position: relative; margin-left: auto; margin-right: auto">
          <div v-if="showPost" class="d-flex justify-center d-md-block" v-html="blok.embed_blockquote">

          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
blockquote.tiktok-embed {
  border-left: none;
}
iframe {
  border: none;
  margin-bottom: -2px;
}
</style>
