<script setup>
  const props = defineProps({ blok: Object });

  import CategoryIcon from "@/components/CategoryIcon.vue";
  import Authors from "@/components/Authors.vue";

  import { computed, ref } from "vue";

  const inView = ref(false);
  function onIntersect(isIntersecting) {
    console.log(isIntersecting);
    inView.value = isIntersecting;
  }

  const srcSet = computed(() => {
    let image = props.blok.story.content.header[0].background_image;
    let focus = props.blok.story.content.header[0].background_image.focus;
    let brightness = props.blok.story.content.header[0].background_brightness;
    let blur = props.blok.story.content.header[0].background_blur;

    return (
      image.filename +
      "/m/736x414/filters:focal(" +
      focus.value +
      "):brightness(" +
      brightness.value +
      "):blur(" +
      blur.value +
      ") 736w," +
      image.filename +
      "/m/800x450/filters:focal(" +
      focus.value +
      "):brightness(" +
      brightness.value +
      "):blur(" +
      blur.value +
      ") 800w," +
      image.filename +
      "/m/928x522/filters:focal(" +
      focus.value +
      "):brightness(" +
      brightness.value +
      "):blur(" +
      blur.value +
      ") 928w"
    );
  });
</script>

<template>
  <section class="hp-section transform-container">
    <v-container
      fluid
      class="pt-0 pl-0 pr-0 mb-4 mb-md-0 one-story-layout-1"
    >
      <router-link :to="{ name: 'article', params: { slug: blok.story.slug } }">
        <v-row class="mb-0 mt-0 ml-0 mr-0 pt-6 pb-12 pb-md-6">
          <v-col
            class="pt-0 pb-0 pl-0 pr-0 pr-md-0 v-col-12 v-col-md-7"
            order="2"
            order-md="1"
          >
            <div
              v-intersect="onIntersect"
              class="slide-in-left with-icon-offset-left"
              v-bind:class="{ 'slide-in-left in-view': inView }"
            >
              <div class="d-block d-md-none aspect-ratio-box-3x4">
                <v-img
                  aspect-ratio="3/4"
                  cover
                  :src="
                    blok.story.content.header[0].background_image.filename +
                    '/m/927x1236/filters:focal(' +
                    blok.story.content.header[0].background_image.focus +
                    ')'
                  "
                  lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                  :alt="blok.story.content.header[0].background_image.alt"
                ></v-img>
              </div>
              <div class="d-none d-md-block aspect-ratio-box-16x9">
                <v-img
                  aspect-ratio="16/9"
                  cover
                  :srcset="srcSet"
                  sizes="(min-width: 960px) 736px, (min-width: 1280px) 800px, (min-width: 1920px) 928px"
                  :src="
                    blok.story.content.header[0].background_image.filename +
                    '/m/736x414/filters:focal(' +
                    blok.story.content.header[0].background_image.focus +
                    ')'
                  "
                  lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                  :alt="blok.story.content.header[0].background_image.alt"
                ></v-img>
              </div>
            </div>
          </v-col>
          <v-col
            class="d-flex flex-column justify-start pa-4 pt-4 pb-0 pa-md-0 pt-md-10 pt-lg-16 v-col-12 v-col-md-4 offset-md-1"
            order="2"
            order-md="1"
          >
            <!--            <Date-->
            <!--                :date="blok.story.first_published_at"-->
            <!--                class="ml-md-4"-->
            <!--            />-->
            <div class="d-flex flex-row">
              <category-icon
                v-if="blok.story.content.category"
                :category="blok.story.content.category"
                class="mr-4"
              />
              <authors :reporters="blok.story.content.reporters" />
            </div>

            <h2 class="heading-highlight smaller-title-section right-border mt-4 mt-md-6 mt-md-11">
              <span>{{ blok.story.name }}</span>
            </h2>
          </v-col>
        </v-row>
      </router-link>
    </v-container>
  </section>
</template>

<style scoped lang="scss">
  .one-story-layout-1 {
    .v-row {
      border-top: dotted 1px #000;
      border-bottom: dotted 1px #000;
    }
  }
  .heading-highlight {
    font-weight: 300;

    &.right-border {
      //border-left: 2px solid #000;
      text-align: left;
      padding-left: 0;

      @media (width >= 960px) {
        border-left: 2px solid #000;
        border-right: none;
        text-align: left;
        padding-left: 20px;
        width: 80%;
      }
    }
    @media only screen and (min-width: 600px) {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
    @media only screen and (min-width: 960px) {
      font-size: 2.2rem;
      line-height: 2.5rem;
    }
    @media only screen and (min-width: 1920px) {
      font-size: 50px;
      line-height: 58px;
    }
  }

  .with-icon-offset-left {
    position: relative;
    .v-img {
      @media (width <= 599px) {
        &:after {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          bottom: 0;
          background-image: url(@/assets/blocks-left-mobile.svg);
          background-size: 40px 40px;
          height: 40px;
          transform: rotate(-90deg);
          z-index: 2;
          aspect-ratio: 1/1;
        }
      }
    }
    &:after {
      @media only screen and (min-width: 600px) {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        aspect-ratio: 1/1;
        background-image: url(@/assets/blocks-left.svg);
        background-size: 72px 72px;
        height: 72px;
        transform: rotate(90deg);
      }
    }
  }

  .border-dotted-b {
    border-bottom: 1px dotted #8c8c8c;
  }

  .border-dotted-t {
    border-top: 1px dotted #8c8c8c;
  }
</style>
