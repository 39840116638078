<script setup lang="ts">
  import { VisSingleContainer, VisDonut, VisBulletLegend } from "@unovis/vue";
  import { dataPie, colors, capitalize, labels } from "./data.ts";

  const items = Object.entries(colors).map(([n, c]) => ({
    name: capitalize(n),
    color: c,
  }));

  function getIcon(f: FormatConfigd, i): string {
    return `<div style="border-radius:100%;width:16px;height:16px;background-color:${f.color};display:inline-block;margin-right:.5rem;"></div>${dataPie[i].key} (${dataPie[i].value}%)\t`;
  }

  const color = (d: labels, i: number) => items[i].color;

  const legendItems = Object.entries(dataPie).map(([_, dataPie]) => ({
    name: dataPie.key.charAt(0).toUpperCase() + dataPie.key.slice(1) + ": " + dataPie.value,
  }));
</script>

<template>
  <h3 class="mb-2">Where does Y Combinator focus its investments?</h3>

  <v-row>
    <v-col>
      <div class="mx-auto w-75">
        <VisSingleContainer width="100%">
          <VisDonut
            :value="(d) => d.value"
            :data="dataPie"
            :arcWidth="0"
            :color="color"
          />
        </VisSingleContainer>
      </div>
      <div
        v-for="(l, i) in labels"
        style="margin-bottom: 10px"
        v-html="getIcon(l, i)"
      ></div>
    </v-col>
  </v-row>
</template>
