<template>
  <div v-if="blok && blok.article.content" class="feature">
    <featured-story-image :blok="blok.article" :backgroundImage="backGroundImage" :backgroundImageMobile="backGroundImageMobile" :standfirstWidth="blok.standfirst_width" :headlineWidth="blok.headline_width" :headlineColour="blok.headline_colour" :headlinePosition="blok.headline_position" :headlineFontFace="blok.headline_font_face" :backgroundBrightness="blok.brightness" :backgroundBlur="blok.blur-sm" :header="blok.article.content.header[0]" :reporters="blok.article.content.reporters" :vertical="blok.article.content.vertical" />
  </div>
</template>

<script setup>

import {computed} from "vue";

const props = defineProps({ blok: Object })

import FeaturedStoryImage from "@/components/FeaturedStoryImage.vue";

const backGroundImage = computed(() => {
  if (props.blok.image && props.blok.image.filename) {
    return props.blok.image
  }

  return props.blok.article.content.header[0].background_image
})

const backGroundImageMobile = computed(() => {
  if (props.blok.image_mobile && props.blok.image_mobile.filename) {
    return props.blok.image_mobile
  }
  else if (props.blok.image && props.blok.image.filename) {
    return props.blok.image
  }
  else if (props.blok.article.content.header[0].background_image_mobile && props.blok.article.content.header[0].background_image.filename) {
    return props.blok.article.content.header[0].background_image_mobile
  }

  return props.blok.article.content.header[0].background_image
})

</script>

<style>


</style>
