<script setup>
  import Spot01 from "@/assets/timeline/spot01.gif";

  import TimelineChart from "@/components/Timeline/Chart.vue";
  import TimelinePieChart from "@/components/Timeline/PieChart.vue";
  import TimelineMobile from "@/components/Timeline/Mobile.vue";

  const isMobile = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  };
</script>
<template>
  <div class="article-page mt-0">
    <p>
      <strong> JAMES HE MISSES OUR CALL – but he has the best possible excuse. </strong>
      <span class="p"
        >The Artificial Societies team is launching a product, and I actually know this before he even sends me an apologetic request to
        push our call a bit later.</span
      >
    </p>
    <p>
      While we were supposed to be chatting, I see an announcement for the startup’s new LinkedIn-post simulator pop up on the very same
      platform.
    </p>
    <p>
      I, naïvely, thought he might have just forgotten about our call – we’d scheduled it for very early West Coast time, after all. But he
      was actually deep in the trenches, shipping something new. Exactly what you’d expect from someone in the thick of Y Combinator’s three
      month accelerator programme, where building and launching at breakout speed is the norm.
    </p>
    <p>
      Six weeks into the programme, he’s measuring time passing not by sprints or product milestones, but by what leftover food he’s been
      eating from the YC dinners. “It’s extending runway,” he jokes. A true founder mindset.
    </p>
    <p>
      It’s a Monday, the day after the team’s weekly reflection meeting (“because we don’t have weekends”). They’d been thinking about
      whether they would’ve been able to move as fast as they are if they were still in London. The answer is no, he says.
    </p>
    <p>
      “Yes, we probably could have learned on our own without sacrificing 7% of our equity, but could we have learned it in seven weeks? No,
      no,” he says. “Could we have built what we’ve built today in seven weeks? No.”
    </p>
    <p>
      Artificial Societies had just released a general product that uses AI to simulate human societies, predicting how marketing and
      content will perform using a simulation of target users. What the team released that morning in February, they had expected to go live
      in June. “They call it an accelerator for a reason,” He says.
    </p>
    <p>
      He and his co-founder Patrick Sharpe moved from London to San Francisco to make their startup happen and are now building alongside
      163 other companies in the W25 batch, each trying to make it to Demo Day.
    </p>
    <p>
      “YC does create a social pressure that everyone is launching, everyone is building in public, everyone is talking to customers, so you
      better get on with it as well,” he says.
    </p>
    <h2>2005-2010: The early days</h2>
    <p>
      The now world-famous accelerator was founded by Paul Graham, Jessica Livingston, Robert Morris and Trevor Blackwell on March 11, 2005.
      Livingston was working at an investment bank and interviewing to join a VC fund. Graham was thinking about getting into angel
      investing and wanted to work with his Viaweb co-founder Morris and early employee Blackwell again,
      <a
        href="https://www.paulgraham.com/ycstart.html"
        class="a"
        target="_blank"
        >according to an account</a
      ><span class="s3"> published in 2012</span> on <span class="s2">Graham’s blog </span>– a collection of essays which is now also
      famous, at least within the startup world.
    </p>
    <p>As Livingston waited to hear back on the job offer, she and Graham decided to start their own investment firm together.</p>
    <p>
      The programme started life as “Cambridge Seed” as they were both based in Cambridge, Massachusetts at the time, but quickly changed to
      Y Combinator when they realised they wanted to have national scope.
    </p>
    <p>
      “Initially we only had part of the idea. We were going to do seed funding with standardised terms,” he writes. “We were going to be
      something there had not been before: a standard source of seed funding.”
    </p>

    <img
      :src="Spot01"
      alt="spot01"
      class="w-100 mb-6"
    />

    <p>
      Like a lot of people who “got rich from technology”, Graham wanted to give seed money to new startups, but it had been nearly seven
      years since Yahoo acquired a three-year-old Viaweb for $49m and he still hadn’t started.
    </p>
    <p>
      Graham hadn’t wanted to be inundated with pitches from former founders, but then he felt guilty. He found a solution to the problem in
      the form of Y Combinator.
    </p>
    <h2>The Summer Founders Program</h2>
    <p>
      The first batch was called the Summer Founders Program. It was like a summer job, Graham wrote, but instead of a salary you got seed
      funding to start your own company with your friends.
    </p>
    <p>
      “Strange as it may sound now, we didn’t expect the startups we funded that summer to succeed,” <span class="s3">he writes</span>. “Our
      goal in funding a bunch of young founders all at the same time was simply to learn how to be good investors. That still drives a
      significant fraction of our decisions.”
    </p>
    <p>
      Y Combinator launched as a kind of experiment, democratising startup funding and offering ambitious, smart college students the
      opportunity to build something rather than following the traditional path of hopping on the corporate ladder.
    </p>
    <p>
      The first batch consisted of just eight companies: Reddit, Kiko (co-founded by Justin Kan and Emmett Shear who went on to start
      Twitch), Clickfacts, TextPayMe, Loopt (co-founded by Sam Altman), Infogami, Memamp and Simmery.
    </p>
    <p>
      Five of those were acquired – including Reddit, which was acquired by Condé Nast just a year later – and three are now inactive, but
      they all made a lasting mark on the future of the YC programme, even though it didn’t seem like they would at the time.
    </p>
    <p>
      “It’s hard for people to realise now how inconsequential YC seemed at the time,” Graham writes. “I can’t blame people who didn’t take
      us seriously, because we ourselves didn’t take that first summer program seriously in the very beginning.”
    </p>
    <h2>Family style</h2>
    <p>
      Jesse Tov, co-founder of Simmery, joined the accelerator because one of his co-founders who was into startups wanted to do it. He
      decided if he could spend a summer working with his friends, why not?
    </p>
    <p>
      Tov’s startup, which shut down not long after the summer, was a web2 social, online dating platform, but the team had originally come
      in with an idea for a website that helped people make resumés. It was Graham that pushed them towards something else.
    </p>
    <p>
      “They didn’t care for the idea, but they’re just like, ‘we like your resumés, you sound like you know what you’re doing,’” Tov tells
      <i>Digital Frontier</i>. “And I think that’s part of the core of Paul Graham’s whole thesis, is that you get the right people and
      they’ll do something.”
    </p>
    <p>
      In 2005, all the founders lived and worked in Boston, and were expected to work out of whatever apartment they lived in. Each company
      was given $6,000 per founder to cover their living expenses.
    </p>
    <p>
      Weekly dinners where founders would get together and talk about what they were working on were run out of Graham and Livingston’s
      house in West Cambridge.
    </p>
    <p>At the time, no one knew what it would become.</p>
    <p>“We didn’t know it was going to survive a year and it’s survived 20 years,” Tov says of the programme.</p>

    <div
      data-v-3dc76b6d=""
      class="v-container v-container--fluid v-locale--is-ltr full-width-quote"
      index="1"
    >
      <div
        data-v-3dc76b6d=""
        class="v-row"
      >
        <div
          data-v-3dc76b6d=""
          class="v-col d-none d-md-flex v-col-2 offset-0 d-flex justify-right v-col-md-2"
        >
          <div
            data-v-3dc76b6d=""
            class="quote-start fill-height"
          ></div>
        </div>
        <div
          data-v-3dc76b6d=""
          class="v-col v-col-12 offset-0 text-center quote-text-container v-col-md-8 offset-md-0"
        >
          <div
            data-v-3dc76b6d=""
            class="quote-text text-left quote-text-spaced"
          >
            You know how sports teams talk about it being like a family culture? That’s actually what it felt like
          </div>
        </div>
        <div
          data-v-3dc76b6d=""
          class="v-col d-none d-md-flex v-col-2 d-flex justify-end v-col-md-2"
        >
          <div
            data-v-3dc76b6d=""
            class="quote-end"
          ></div>
        </div>
      </div>
      <div
        data-v-3dc76b6d=""
        class="v-row mt-0"
      >
        <div
          data-v-3dc76b6d=""
          class="v-col v-col-12 offset-0 pt-0 pt-md-4 v-col-md-4 offset-md-3 font-semibold"
        >
          <p
            data-v-3dc76b6d=""
            class="quote-credit"
          >
            Adam Siegel, Inkling (W06)
          </p>
        </div>
      </div>
    </div>

    <TimelineMobile
      :class="isMobile() ? 'mobile' : 'desktop'"
      v-if="isMobile()"
    />

    <p>
      Simmery never took off, but Y Combinator actually helped Tov realise that startups were not what he wanted to do. While most people
      know YC for the household name founders it has produced, many alumni like Tov end up choosing different paths; you can find them in
      education, venture capital and in senior positions at tech companies across the world. After 20 years, it’s an extensive network.
    </p>
    <h2>The YC of SV</h2>
    <p>
      The next year, Y Combinator ran two batches, in Silicon Valley in the winter and in Boston during the summer. Graham and Livingston
      didn’t want someone to copy what they were doing and start “the Y Combinator of Silicon Valley”. They wanted YC to be that.
    </p>
    <p>
      It was a last minute decision to move to the West Coast and, had they had more time they would have found an office in Berkeley, but
      there wasn’t time to find their own building anywhere. So they took over part of Blackwell’s offices at his robotics company Anybots
      in Mountain View. During the first dinner the founders were told not to touch the walls because the paint was still wet.
    </p>
    <p>
      The second batch was similarly small, just seven companies. Two of those are now inactive – including Audiobeta, co-founded by Ben
      Silberman who went on to co-found Pinterest – but the other five were acquired. One of those companies was Inkling, co-founded by Adam
      Siegel.
    </p>
    <p>
      After a decade in consulting, Siegel was looking for something else to do. A friend who had been to Graham’s first startup school told
      him about it, prompting a trip to Boston where Siegel and his co-founders pitched Graham and Livingston. They felt “very startup”,
      Siegel says, barely asking the team about the actual business idea.
    </p>
    <p>
      “It was just a really weird conversation, and not one we were expecting to have,” Siegel tells <i>Digital Frontier</i>. “Then they
      said, ‘thank you’, and we walked out. And we were both just sort of flabbergasted, like, ‘What the fuck was that?’ It was just not
      what we were expecting at all, especially coming from these corporate backgrounds.”
    </p>
    <p>
      After aimlessly wandering around Cambridge all day waiting, they got a call, were told they’d got in and were given just 15 minutes to
      decide if they wanted to give up 10% of their company for around $18,000. They decided to go for it.
    </p>
    <p>
      Graham himself was the real draw. He was already a real powerhouse figure in the startup world and had become a trusted source of
      wisdom. Within their corporate lives, Siegel and his co-founders had subscribed to the “rebel startup ethos” that Graham represented.
      “His writing spoke to us,” he says. “And I think that’s what attracted us most.”
    </p>
    <p>
      As Siegel puts it, they drank the Kool-Aid. The team followed the classic path of camping out for three months and doing nothing but
      eating, sleeping and breathing their startup.
    </p>
    <p>
      “You know how sports teams talk about it being like a family culture? That’s actually what it felt like,” he says. “It didn’t feel too
      competitive. We were all talking to each other. We were all sharing things. Paul, Jessica, Trevor, those guys were just always
      available.”
    </p>

    <div class="mb-8">
      <div style="padding: 56.25% 0 0 0; position: relative">
        <iframe
          src="https://player.vimeo.com/video/1064604703?badge=0&amp;title=0&amp;background=1&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          title="spot03"
        ></iframe>
      </div>
    </div>

    <p>
      Siegel looks back on it fondly as a time that was incredibly productive – feeling concerned about the future and how to make money,
      but still optimistic about what they were building. And, in retrospect, “getting access to Paul like that was a real gift”.
    </p>
    <h2>‘The pantheon of Y Combinator’</h2>
    <p>
      When YC began, it had an early hacker ethos – batch sizes were small and so all the founders had the chance to develop close, personal
      relationships with the group partners. Now, hundreds of startups go through the process each year.
    </p>
    <p>
      What remains the same, though, is the importance of the early injection of cash that the programme offers. Instead of worrying about
      getting out there and raising money, founders can just focus on building.
    </p>
    <p>
      Siegel ended up setting up a new company, Cultivate Labs, and acquiring Inkling to reset the cap table when he and his co-founders
      decided to part ways. The company has been building slowly but surely over the past 19 years. It hasn’t taken on big venture capital
      money and it hasn’t become a unicorn.
    </p>
    <p>
      “In the pantheon of Y Combinator, there’s these handful of companies that have become worth tens of billions of dollars, they’re
      household names. And then there’s
      <a
        href="https://www.paulgraham.com/die.html"
        target="_blank"
        >hundreds of others</a
      >
      which just quietly died, or quietly just continued on. And those don’t really get talked about at all, because they’re not the
      headline-making companies, but they’re out there,” he says.
    </p>
    <p>
      “I think that’s a much quieter part of the Y Combinator legacy, is that there’s now companies that are five, ten, 15 years old, that
      still operate to this day.”
    </p>
    <h2>YC’s first big success</h2>
    <p>
      In 2007, Dropbox joined YC. The file sharing solution was founded by Drew Houston and Arash Ferdowsi and proved to be one of the
      accelerator’s first breakout successes. (While Reddit became a household name and was at one point valued at $10bn, the returns from
      its IPO did not benefit YC).
    </p>
    <p>
      Michelle You, co-founder of Songkick, was part of S07 with Dropbox. They were the last Boston class before operations moved entirely
      to the West Coast in Mountain View. It stayed there until 2019, but had a brief stint in Palo Alto from 2013 to 2014, putting it
      closer to Stanford and the centre of Silicon Valley’s startup scene.
    </p>
    <p>
      Like Siegel, You found out about YC through word of mouth: her co-founder’s cousin had read Graham’s book and recommended it. At the
      time, “starting a company was not a well understood thing,” she says. “This was pre the heyday of Facebook, it was just a very rare
      and weird thing to do. And YC wasn’t yet a cultural phenomenon.”
    </p>
    <p>
      As it still is now, one of the biggest benefits of YC for You was being in a cohort of peers all working on the same thing: starting a
      company.
    </p>
    <p>
      “It’s really hard and lonely, and it gives you this support network to talk about such a brutal and difficult experience,” You says.
      She is still close friends with people from her batch – they even went to each other’s weddings.
    </p>
    <p>
      Peter Nixey, who founded ClickPass and was in the same batch as You and the Dropbox founders, explains how powerful this network of
      peers can be.
    </p>
  </div>
</template>

<style scoped lang="scss">
  .full-width-quote {
    .quote-text-container {
      position: relative;
      z-index: 1;
      .quote-text {
        font-size: 1.9em;
        padding-top: 0;
        line-height: 1.2em;
        font-family: "DepartureMonoRegular", sans-serif !important;
        font-weight: normal;
        @media only screen and (max-width: 599px) {
          margin-top: 24px;
        }
      }
      .quote-text-spaced {
        margin: 0 24px;
      }
      @media only screen and (max-width: 599px) {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          background-image: url(@/assets/quote.svg);
          transform: rotate(180deg);
          background-position: bottom right;
          background-repeat: no-repeat;
          background-size: 80%;
        }
      }
    }
    @media only screen and (max-width: 599px) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  .quote-start {
    background-image: url(@/assets/quote.svg);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
  }
  .quote-end {
    background-image: url(@/assets/quote.svg);
    transform: rotate(180deg);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
  }
  .article-page .quote-credit {
    padding-bottom: 0;
    margin-bottom: 0;
  }
</style>
