<script setup>
  const props = defineProps({
    hosts: Array,
    guests: Array,
    socials: Object,
  });

  const hostImage = (host) => {
    return host?.content?.profile_picture?.filename || "";
  };

  const getFirstContactLink = (person) => {
    const contactTypes = ["email", "twitter", "linkedin", "instagram"];
    for (const type of contactTypes) {
      if (person[type]) {
        let url;
        switch (type) {
          case "email":
            url = `mailto:${person[type]}`;
            break;
          case "twitter":
            url = `https://x.com/${person[type]}`;
            break;
          case "linkedin":
            url = `https://linkedin.com/in/${person[type]}`;
            break;
          case "instagram":
            url = `https://www.instagram.com/${person[type]}`;
            break;
          default:
            url = null;
        }
        return { type, url };
      }
    }
    return;
  };
</script>
<template>
  <div>
    <template v-for="(people, type) in { hosts: hosts, guests: guests }">
      <div
        v-if="people"
        class="mb-6 space-y-6 flex flex-col gap-2"
      >
        <h3 class="text-h7 font-bold capitalize">{{ people.length === 1 ? type.slice(0, -1) : type }}:</h3>
        <div
          v-for="person in people"
          class="flex gap-3 align-center"
        >
          <img
            v-if="person?.content?.profile_picture?.filename != ''"
            :src="hostImage(person)"
            class="rounded-full overflow-hidden h-8 w-8 mb-1"
          />

          <div v-if="getFirstContactLink(person.content)">
            <a
              :href="getFirstContactLink(person.content).url"
              class="text-h8"
              target="_blank"
              >{{ person.name }}</a
            >
          </div>
          <div v-else>
            <div class="text-h8">{{ person.name }}</div>
          </div>
        </div>
      </div>
    </template>
    <!-- SOCIALS -->
    <h3 class="text-h7 font-bold mt-4">Listen on:</h3>
    <div class="flex align-center">
      <template
        v-for="social in socials"
        :key="social"
      >
        <a
          :href="social.content.url"
          target="_blank"
          v-if="social.content.url"
          icon
          variant="text"
          color="black"
          size="large"
          class="w-8"
        >
          <v-icon v-if="social.icon">{{ social.icon }}</v-icon>
          <img
            v-else
            :src="social.image"
            class="w-8"
          />
        </a>
      </template>
    </div>
  </div>
</template>
