<script setup>
  import { onMounted, ref } from "vue";
  import gsap from "gsap";
  import ScrollTrigger from "gsap/ScrollTrigger";
  import { useTimeline } from "@/composables/useTimeline";
  const { events } = useTimeline();
  const mainRef = ref(null);

  gsap.registerPlugin(ScrollTrigger);

  function setOffScreen(el) {
    var rect = el.getBoundingClientRect();
    return -screen.width - rect.left + el.offsetWidth / 2;
  }

  onMounted(() => {
    gsap.set(".line", { transformOrigin: "center top", xPercent: 0, x: 0, yPercent: 0, scaleY: 1 });
    gsap.set(".line-a", { transformOrigin: "left top", xPercent: 0, x: 0 });
    gsap.set(".b .line-a", { transformOrigin: "left top", xPercent: 0, x: 0 });
    // gsap.set(".logo", { transformOrigin: "center center" });

    let q = gsap.utils.selector(mainRef.value);
    const boxHolders = q(".box-holder");
    const logos = q(".image-container ");
    const moneys = q(".money ");

    logos.forEach((box) => {
      gsap.set(box, {
        x: (i, t) => {
          return setOffScreen(t);
        },
      });
    });

    boxHolders.forEach((box) => {
      gsap.from(box, {
        scale: 0,
        opacity: 0,
        duration: 0.5,
        transformOrigin: "left center",
        scrollTrigger: {
          trigger: box,
          start: "top 60%",
          toggleActions: "play none none none",
        },
      });
    });

    logos.forEach((logo) => {
      gsap.to(logo, {
        // scale: 0,
        // opacity: 0,
        rotation: 360,
        x: "0",
        duration: 10,
        ease: "elastic.out(0.2,0.4)",
        transformOrigin: "center center",

        duration: 1,
        scrollTrigger: {
          trigger: logo,
          start: "top 60%",
          toggleActions: "play none none none",
        },
      });
    });

    moneys.forEach((money) => {
      gsap.from(money, {
        scale: 0,
        opacity: 0,
        scrollTrigger: {
          trigger: money,
          start: "top 60%",
          toggleActions: "play none none none",
        },
      });
    });
    // end onMounted
  });
</script>

<template>
  <div
    class="timeline-container"
    ref="mainRef"
  >
    <div class="box box--with-echo">
      <div class="box__content">Y Combinator’s top 20 most valuable companies</div>
    </div>

    <template v-for="(event, i) in events">
      <div
        class="event position-absolute w-100 d-flex justify-center"
        :style="`top: ${((i + 1) * 10) / 2.44}% `"
      >
        <div
          class="logo d-grid justify-end"
          :class="event.logos.length > 2 ? 'logo--grid' : 'd-flex'"
          v-if="event.logos"
        >
          <div
            :class="[`logo-${logo.name}`, event.logos.length > 2 ? 'logo--grid__container' : 'no-grid__container']"
            v-for="(logo, i) in event.logos"
          >
            <div class="image-container">
              <img
                :src="logo.url"
                :alt="logo.name"
                class="w-full"
              />
            </div>
            <p
              class="money"
              v-if="event.amounts"
            >
              ${{ event?.amounts[i] }}bn
            </p>
          </div>
        </div>

        <div v-else></div>

        <div
          class="box-holder"
          :class="event?.year ? 'box-holder--no-border' : ''"
        >
          <div class="line-a line-h"></div>
          <div class="line-h__dot"></div>
          <div class="box">
            <div class="box__content">
              {{ event?.year ? event.year : event.text }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- <div class="a box-holder">
                <div class="line-a line-h"></div>
                <div class="box purple">
                  <div class="box__content">
                    Y Combinator was founded by Paul Graham, Jessica Livingston, Robert Morris and Trevor Blackwell and launched with the
                    Summer Founders Program
                  </div>
                </div>
              </div> -->

    <div class="line-wrapper">
      <div class="line"></div>
      <!-- <div class="line-dot"></div> -->
    </div>
    <p class="reddit-note">*Reddit was sold to Condé Nast in 2006. <br />The returns from its IPO did not benefit Y Combinator.</p>
  </div>
</template>

<style lang="scss">
  $timelineHeight: calc(90% - 24px);

  .spacer {
    height: 500px;
  }

  .timeline-container {
    height: $timelineHeight;
    position: relative;

    .box {
      width: 200px;
      position: absolute;
      z-index: 100;
      position: relative;
      z-index: 1;
      left: -5px;
      z-index: 1;
      // font-size: 0.6rem;

      &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #f26522;
        border-radius: 5px;
        color: #f26522;
        font-size: 13px;
        background-color: #f4f2ee;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 5;
        padding: 10px;
      }

      &--with-echo {
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        width: 260px;

        .box__content {
          font-size: 0.9rem;
          font-weight: bold;
        }

        &:after {
          content: "";
          border: 1px solid #f26522;
          border-radius: 5px;
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
          top: -4px;
          left: -4px;
        }
      }
    }

    .event {
      left: 50%;
      transform: translateX(-50%);
      > div {
        width: 50%;
      }
    }

    .box-holder {
      // position: absolute;
      display: flex;
      align-items: center;
      // transform: translateY(-50%);
      // left: 50%;

      &--no-border {
        .box {
          width: auto;
        }
        .box__content {
          border: none;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    .line-wrapper {
      height: 100%;
      width: 1px;
      top: -55px;
      left: 50%;
      position: relative;

      .line {
        width: 1px;
        height: 100%;
        position: absolute;
        left: calc(50% - 1px);
        top: 0px;
        /*   visibility: hidden; */
        background-color: #f26522;

        &:after {
          content: "";
          background: #f4f2ee;
          width: 14px;
          height: 14px;
          border-radius: 100%;
          border: 2px solid#f26522;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
        }
      }
    }

    .logo {
      gap: calc(0.25rem * 4);
      padding-right: 30px;
      text-align: center;

      .image-container {
        // max-height: 70px;
        // max-width: 130px;
        // min-height: 70px;
        // min-width: 130px;
      }

      p {
        text-align: center;
        background-color: #f26522;
        display: inline-flex;
        padding: 0.2rem 0.4rem;
        width: 100px;
        color: white;
        font-weight: bold;
        justify-content: center;
      }
    }

    .logo--grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 1rem;
      grid-row-gap: 0.6rem;
      justify-items: center;
      align-items: center;

      &__container {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
      }
    }

    .no-grid__container {
      display: flex;
      height: 100%;
      width: 50%;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      gap: 24px;

      .image-container,
      p {
        align-self: center;
      }
    }

    .image-container {
      max-height: 70px;
    }

    .line-h {
      width: 45px;
      height: 1px;
      left: 0;
      top: 50%;
      /*   visibility: hidden; */
      background-color: #f26522;
    }
    .line-h__dot {
      background: #f4f2ee;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      border: 2px solid#f26522;

      z-index: 5;
      right: 0px;
      top: 50%;
      // transform: translateY(-50%);
    }
    .line-dot {
      content: "";
      width: 10px;
      height: 40px;
      border-radius: 100%;
      border: 2px solid#f26522;
      position: absolute;
      left: 50%;
      bottom: 0px;
      transform: translateX(-50%);
    }
  }

  .desktop {
    .logo {
      img {
        height: 100%;
        width: 100%;
      }

      .logo-1 {
        .image-container {
          max-width: 150px;
          min-width: 150px;
          gap: 20px;
        }
      }

      .logo-2 {
        .image-container {
          max-width: 170px;
          min-width: 150px;
          gap: 20px;
        }
      }

      .logo-6 {
        gap: 14px;
        .image-container {
          max-height: 90px;
          min-height: 90px;
        }
      }

      .logo-41,
      .logo-42,
      .logo-43,
      .logo-44 {
        height: auto;
        gap: 14px;
        .image-container {
          min-height: 0;
        }
      }

      .logo-41 {
        .image-container {
          max-height: 40px;
          max-width: 120px;
        }
      }

      .logo-42 {
        .image-container {
          max-height: 40px;
        }
      }

      .logo-43 {
        .image-container {
          max-width: 90px;
          min-width: 90px;
        }
      }

      .logo-44 {
        .image-container {
          max-height: 40px;
        }
      }

      .logo-51 {
        gap: 0;
        height: auto;
        .image-container {
          margin-top: 10px;
          min-width: 120px;
        }
      }

      .logo-52 {
        gap: 10px;
        height: auto;
        .image-container {
          min-width: 100px;
        }
      }

      .logo-6 {
        .image-container {
          max-height: 80px;
          margin-left: -6px;
        }
      }

      .logo-71,
      .logo-72 {
        height: auto;
        gap: 0;
        .image-container {
          max-width: 180px;
        }
      }

      .logo-72 {
        margin-top: 14px;
      }

      .logo-81,
      .logo-82 {
        gap: 10px;
        .image-container {
          max-width: 90px;
          min-width: 90px;
        }
      }

      .logo-91,
      .logo-92,
      .logo-93 {
        height: auto;
        gap: 14px;
        .image-container {
          min-height: 0;
        }
      }

      .logo-91 {
        .image-container {
          max-width: 160px;
          min-width: 160px;
        }
      }

      .logo-92 {
        gap: 14px;
        .image-container {
          max-width: 80px;
          min-width: 80px;
          max-height: 80px;
        }
      }

      .logo-93 {
        .image-container {
          max-width: 90px;
          min-width: 90px;
        }
      }

      .logo-94 {
        gap: 14px;
        .image-container {
          max-height: 90px;
          min-height: 90px;
        }
      }

      .logo-95 {
        gap: 14px;
        .image-container {
          max-width: 100px;
          min-width: 100px;
          max-height: 80px;
        }
      }
    }
  }

  .reddit-note {
    font-size: 0.8rem;
    line-height: 1.2;
    font-style: italic;
    color: #f26522;
    text-align: center;
    max-width: 420px;
    margin: 2rem auto;
  }
</style>
