<script setup>
  import TimelineChart from "@/components/Timeline/Chart.vue";
  import TimelinePieChart from "@/components/Timeline/PieChart.vue";

  import Spot05 from "@/assets/timeline/spot05.jpg";
  import ycBanner from "@/assets/yc-banner.png";
  import ycMpu from "@/assets/yc-mpu.png"
  import {useAccountStore} from "@/stores/account.js";
  import router from "@/router/index.js";

  const isMobile = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  };

  const accountStore = useAccountStore();

  const handlePromo= () => {
    accountStore.setPrice("digitalOnlyMonthly");
    router.push({ name: "pay" });
  }
</script>
<template>
  <div class="article-page body-text">
    <v-row>
      <v-col>
        <div
          data-v-3dc76b6d=""
          class="v-container v-container--fluid v-locale--is-ltr full-width-quote"
          index="1"
        >
          <div
            data-v-3dc76b6d=""
            class="v-row"
          >
            <div
              data-v-3dc76b6d=""
              class="v-col d-none d-md-flex v-col-2 offset-0 d-flex justify-right v-col-md-2"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-start fill-height"
              ></div>
            </div>
            <div
              data-v-3dc76b6d=""
              class="v-col v-col-12 offset-0 text-center quote-text-container v-col-md-8 offset-md-0"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-text text-left quote-text-spaced"
              >
                The motto still is so relevant: make something people want. It’s so simple, but it’s so, so true and I don’t think that will
                ever change
              </div>
            </div>
            <div
              data-v-3dc76b6d=""
              class="v-col d-none d-md-flex v-col-2 d-flex justify-end v-col-md-2"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-end"
              ></div>
            </div>
          </div>
          <div
            data-v-3dc76b6d=""
            class="v-row mt-0"
          >
            <div
              data-v-3dc76b6d=""
              class="v-col v-col-12 offset-0 pt-0 pt-md-4 v-col-md-4 offset-md-3 font-semibold"
            >
              <p
                data-v-3dc76b6d=""
                class="quote-credit"
              >
                Michelle You, Songkick (S07)
              </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="5"
        class="position-relative"
      ></v-col>
      <v-col
        cols="12"
        md="7"
      >
        <p>
          “It’s kind of ridiculous to see the level of the tide that my contemporaries have risen to,” he says. “But the thing that I really
          valued at the time was being around exceptional people. It was just a completely different level of execution and agency and smart
          and belief.”
        </p>
        <p>
          It was clear that Dropbox would make it big, he says, because everyone in the batch was already using it. For many founders, even
          today, their batchmates make their first customers, and sometimes even their first angel investors. Being surrounded by people who
          want to use your product provides a good testing ground.
        </p>
        <p>
          You also found great support in the now extensive alumni network. She has invested in other companies that have been through YC,
          and has received angel funding too. Gustaf Alströmer, who acts as a group partner shepherding fresh batches of founders through
          YC, invests in Supercritical, a startup You co-founded with Songkick CTO Aaron Randall in 2021.
        </p>
        <v-img
            :src="ycBanner"
            alt="Free access promo"
            class="d-none d-md-block w-100 show-link"
            @click="handlePromo"
        />
        <v-img
            :src="ycMpu"
            alt="Free access promo"
            class="d-block d-md-none w-100 show-link mb-6"
            @click="handlePromo"
        />
        <h2>Growth and influence</h2>
        <p class="s6">
          Like many others, Nixey knew about YC through Graham’s essays, and he’d also been reading Hacker News, YC’s social news website
          that consists of “<a
            href="https://news.ycombinator.com/newsguidelines.html"
            class="s5"
            target="_blank"
            >anything good hackers would find interesting</a
          >.” (It is now
          <a
            href="https://venturebeat.com/business/y-combinator-spins-out-hacker-news-to-give-it-full-editorial-independence/"
            class="s5"
            target="_blank"
            >editorially independent from YC</a
          >
          and moderated by the elusive Gackle.) That’s what drew Nixey to join YC – he didn’t even know where Silicon Valley was at the
          time.
        </p>
        <p>
          “Even at that point, it was a brand that opened doors and made people pay attention,” Nixey says. “When I joined, Reddit had just
          been acquired, which was the line in the sand that showed that something was happening there.”
        </p>
        <p>
          Even then, people were sceptical. You recalls having to call the parents of one of their first engineers to convince them he was
          taking a real job for a real company.
        </p>
        <p>“It was not seen as a high reputation thing to do,” she says. “It was seen as very strange and risky.”</p>
        <p>
          Luckily for You, her own parents both worked in tech and her mother was a VC, so they understood. But being surrounded by other
          people building and being with some of the best founders in the world helped massively. “That really sets the benchmark for what
          you think ‘good’ looks like,” she says.
        </p>
        <p>
          YC was one of the first programmes that invested in the founder and their abilities, bringing that hacker ethos into
          entrepreneurship, and the focus was not on building business but on building something useful.
        </p>
        <p>
          “The motto still is so relevant: make something people want,” You says. “It’s so simple, but it’s so, so true and I don’t think
          that will ever change.”
        </p>
        <h2>2010-2015: The YC boom</h2>
        <p>
          By 2010, YC had established itself as the most prestigious startup accelerator, and the next five years fully cemented its
          influence on the tech industry at large.
        </p>
        <p>
          Some of the programme’s most iconic companies joined during this time, including its four
          <span class="s7"
            >most successful and highest valued startups – Airbnb, Stripe, Coinbase and Doordash. It proved it could back billion-dollar
            startups, and these four companies now account for more than 85% of the market value created by YC companies.</span
          >
        </p>
        <p>
          YC was, and still is, playing a numbers game. And it pays off. Around 4.5% of YC companies become unicorns compared to roughly
          2.5% for other venture-backed seed-stage companies.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <!-- quote here-->
        <div
          data-v-3dc76b6d=""
          class="v-container v-container--fluid v-locale--is-ltr full-width-quote"
          index="1"
        >
          <div
            data-v-3dc76b6d=""
            class="v-row"
          >
            <div
              data-v-3dc76b6d=""
              class="v-col d-none d-md-flex v-col-2 offset-0 d-flex justify-right v-col-md-2"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-start fill-height"
              ></div>
            </div>
            <div
              data-v-3dc76b6d=""
              class="v-col v-col-12 offset-0 text-center quote-text-container v-col-md-8 offset-md-0"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-text text-left quote-text-spaced"
              >
                YC was gifted at building a community of talented, ambitious and entrepreneurial founders. That network and community meant
                a lot to me
              </div>
            </div>
            <div
              data-v-3dc76b6d=""
              class="v-col d-none d-md-flex v-col-2 d-flex justify-end v-col-md-2"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-end"
              ></div>
            </div>
          </div>
          <div
            data-v-3dc76b6d=""
            class="v-row mt-0"
          >
            <div
              data-v-3dc76b6d=""
              class="v-col v-col-12 offset-0 pt-0 pt-md-4 v-col-md-4 offset-md-3 font-semibold"
            >
              <p
                data-v-3dc76b6d=""
                class="quote-credit"
              >
                Stanley Tang, DoorDash (S13)
              </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="5"
        class="position-relative"
      ></v-col>

      <v-col
        cols="12"
        md="7"
      >
        <p>
          Competition to get into the programme intensified in the 2010s and the acceptance rate started to rival that of elite
          universities. The acceptance rate now is just 1%.
        </p>
        <p>
          The next few years were huge for YC. Batch sizes started to grow, and by 2015 there were more than 200 companies going through the
          programme a year.
        </p>
        <p>
          Curtis Liu co-founded Amplitude, a digital analytics platform, alongside Spenser Skates in 2012. But the two founders went through
          YC in batch W12 with an entirely different company. At the time they were working on Sonalight, a voice recognition app. It wasn’t
          until two years after the programme that they pivoted to a new product.
        </p>
        <p>
          Liu was working at Google when Sonalight was accepted into YC, and despite always being interested in starting a company, he had
          thought he’d work at a large company like Google, gain experience, work at a startup to understand what it means to start a
          business and then launch his own company.
        </p>
        <p>
          At Google he quickly realised working in a large company was not for him. “Things moved pretty slowly and it kind of made me a cog
          in a wheel,” he says.
        </p>
        <p>
          He knew Drew Houston, who had gone through YC with Dropbox and recommended it, but the accelerator was also starting to gain a lot
          of traction in the startup world more generally.
        </p>
        <p>
          “Y Combinator at the time was getting really much more in the media and well understood what exactly it was,” he says, “but it was
          basically the premier support system for anyone that wanted to start a company.”
        </p>
        <h2>Developing a brand</h2>
        <p>
          It was also still unique at the time that YC gave startups money upfront to go work on their startup and allowed them to skip the
          whole fundraising process. It provided a structure and system to get a startup up and running.
        </p>
        <p>
          “We were right at that inflection point,” Liu says. There were 66 companies in W12, and YC was growing in popularity, but the
          batches were still small enough for founders to have a lot of time interacting with the partners. “I think it was an unusual
          time,” he says. It was also just before the initial investment amounts changed.
        </p>
        <p>
          At the time, YC offered about $11,000 plus $5,000 per founder. Liu’s batch was the first with a guaranteed $150,000 convertible
          note after finishing the programme.
        </p>
        <p>
          In 2011, YC partnered with tech entrepreneur Yuri Milner and venture fund SV Angel to create Start Fund, which automatically
          invested $150,000 in every startup going through YC on an uncapped convertible note. This was later reduced to $80,000 on top of
          YC’s $17,000, was renamed YCVC and went through various iterations of which partners were involved.
        </p>
        <p>
          When Sam Altman took over from Graham as president in 2014 (more on this later), he scrapped YCVC, and all startups simply
          received $120,000 from YC for 7% equity. This investment has increased to $500,000 now, but at the time the move was to simplify
          terms and provide companies with enough money to run their business and live in the Bay Area for at least six months.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- spot05 -->

        <div class="mb-4 w-md-75 mx-auto">
          <img
            :src="Spot05"
            class="w-100"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="5"
      ></v-col>
      <v-col
        cols="12"
        md="7"
      >
        <p>
          “If you wanted to go through an accelerator, Y Combinator was the one to do,” Liu says. “They developed a brand pretty early on.
          There were some other accelerators, but none of them had the same reputation, even at that time, as YC.”
        </p>
        <h2>Pivoting</h2>
        <p>
          At the end of the programme, Liu and Skates had an impressive and successful Demo Day – it’s the day everything leads up to, the
          grand finale at the end of each batch where the founders take to the stage and pitch their startups to a room full of investors,
          media and the rest of their cohort. The pitch went well, but they soon found that retention rates were poor.
        </p>
        <p>
          Liu and Skates had made the decision to stick it out at the company for at least two years, so shutting down wasn’t an option, but
          changing direction to an entirely new product was. So a few years later, they pivoted.
        </p>
        <p>
          As part of going through YC, they had built their own system for understanding their growth – how many users they had, how the
          company was growing, what parts of the product people were interacting with.
        </p>
        <p>
          As it turned out, that’s exactly what other companies in their batch wanted and needed and the company took off, eventually going
          public in 2021. Altman was an early backer of the new idea, and having “that level of close access to somebody who had experience”
          was invaluable.
        </p>
        <p>
          “They kind of broke open the gates to make it more accessible to equip founders with tools to understand what goes into a
          fundraise, what goes into building a business,” Liu says. “And so ultimately, I think they’ve just made the ecosystem a lot
          friendlier for people that are outside of it.”
        </p>
        <h2>DoorDash joins the fold</h2>
        <p>
          Like many others, Stanley Tang found Y Combinator through Graham – specifically, through his essays. He started reading them as a
          high school student in Hong Kong, and that’s what initially inspired him to move to Silicon Valley and pursue tech and startups.
        </p>
        <p>
          He went to Stanford University and met Andy Fang, who would go on to be one of his DoorDash co-founders. About a month into his
          freshman year, YC held an event on campus with Graham, Houston and Mark Zuckerberg.
        </p>
        <p>
          “It cemented that it was a genuine possibility to bring our passions and big ideas to life,” he tells <i>Digital Frontier</i>. He
          was just 19 when he went through the programme, but he describes it as “one of the defining experiences” of his life and career.
          “YC was gifted at building a community of talented, ambitious and entrepreneurial founders. That network and community meant a lot
          to me.”
        </p>
        <p>
          Now a tech unicorn valued at $73bn, the food delivery startup began life as PaloAltoDelivery.com, a local delivery service for
          Stanford students. As it scaled, YC continued to play a vital role.
        </p>
        <p>
          “The YC community has continued to support me as we’ve built and scaled DoorDash from a small startup into a company that operates
          in more than 30 countries,” Tang says. “If you’re a founder or an engineer with a big idea, it provides a unique environment where
          people can join or create startups that can genuinely succeed.”
        </p>
        <p class="s6">
          Last summer he even went to one of YC’s alumni retreats. It was there that Airbnb founder Brian Chesky, who went through YC in
          2009, gave a speech about management advice that
          <a
            Last
            href="https://paulgraham.com/foundermode.html"
            target="_blank"
            >inspired Graham’s essay</a
          >
          about “<a
            href="https://digitalfrontier.com/articles/founder-mode-taste-buzzwords-y-combinator-airbnb-brian-chesky"
            target="_blank"
            >founder mode</a
          ><span>”.</span>
        </p>
        <h2>The era of Altman</h2>
        <p>
          By 2014, Graham and Livingston were balancing family life with their two young kids alongside running a burgeoning Y Combinator.
          They were looking for a way to take a step back, and it came in the form of a not-quite-30-year-old Sam Altman.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <!-- quote -->
        <div
          data-v-3dc76b6d=""
          class="v-container v-container--fluid v-locale--is-ltr full-width-quote"
          index="1"
        >
          <div
            data-v-3dc76b6d=""
            class="v-row"
          >
            <div
              data-v-3dc76b6d=""
              class="v-col d-none d-md-flex v-col-2 offset-0 d-flex justify-right v-col-md-2"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-start fill-height"
              ></div>
            </div>
            <div
              data-v-3dc76b6d=""
              class="v-col v-col-12 offset-0 text-center quote-text-container v-col-md-8 offset-md-0"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-text text-left quote-text-spaced"
              >
                I kind of wish [Y Combinator] was still a secret, the cool kids’ secret, the uncool kids’ secret. But I think it is better
                for this world that there’s more good companies and there’s more jobs
              </div>
            </div>
            <div
              data-v-3dc76b6d=""
              class="v-col d-none d-md-flex v-col-2 d-flex justify-end v-col-md-2"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-end"
              ></div>
            </div>
          </div>
          <div
            data-v-3dc76b6d=""
            class="v-row mt-0"
          >
            <div
              data-v-3dc76b6d=""
              class="v-col v-col-12 offset-0 pt-0 pt-md-4 v-col-md-4 offset-md-3 font-semibold"
            >
              <p
                data-v-3dc76b6d=""
                class="quote-credit"
              >
                Yan Lhert, Zen99 (S14)
              </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="5"
        class="position-relative"
      ></v-col>

      <v-col
        cols="12"
        md="7"
      >
        <p>
          An alumnus of the original batch, Altman had sold his company Loopt and had been working as a part-time YC partner since 2011. He
          had known Graham since he was a teenager, and now he was stepping into his shoes and taking over as president.
        </p>
        <p>
          “Y Combinator is going to have to grow,” Graham told a conference audience just after announcing the handover of power. He had
          confidence that Altman would be a better steward of a large organisation than he would.
        </p>
        <p>
          At this point, YC had funded 632 companies over seven years, and Graham and Livingston didn’t even make a list of possible
          successors, the role went straight to Altman.
        </p>
        <p>
          Up until that point, YC had mostly consisted of consumer apps and enterprise software companies, but at this stage Altman’s top
          priority was going bigger and broader than YC had gone before. He went for wilder startup ideas and pushed founders to think
          bigger. He was soon seen as a figure as steadfast and iconic as Graham.
        </p>
        <p>
          Yan Lhert, co-founder of Zen99, was part of the first batch Altman oversaw in summer 2014. Graham was still around, but he was
          starting to step back.
        </p>
        <p>
          “There’s certainly something about Sam that he was clearly interested in taking bigger bets and growing outside of being a fun
          little software school,” Lhert tells <i>Digital Frontier</i>. “Especially in our batch, there was clearly a lot of tinkering going
          on in terms of, what if we were to just accept this crazy nuclear fusion company too?”
        </p>
        <p>
          Altman persuaded the founders of Helion, a nuclear fusion startup, to go through the programme in the same batch as Lhert. He also
          got Cruise – now the most heavily funded YC company ever – to go through the programme in W14. Tristan Zier, Lhert’s co-founder,
          is now chief of staff at the self-driving car company.
        </p>
        <p>
          “As it grew and grew, it sort of stopped being as [much of a] family,” Lhert says. “Clearly, we were the beginning of YC as an
          institution, and not YC being the Paul Graham family.”
        </p>
        <p>He describes Graham as the thinker, a philosopher father figure, and Altman as more of an executor.</p>
        <p>
          <span class="p"
            >“Ultimately, yes, it’s sad to lose some of that hacker, scrappy punk rock mentality,” he says. “In some ways, I don’t love that
            YC is a household name. </span
          ><span class="s8">I kind of wish it was still a secret,</span><span class="p"> </span
          ><span class="s8">the cool kids’ secret, the uncool kids’ secret. But I think it is better for this world that, there’s</span
          ><span class="p"> </span><span class="s8">more good companies and there’s more jobs</span><span class="p">.”</span>
        </p>
        <h2>Defining success</h2>
        <p>
          Unlike previous batches where everyone knew everyone, Lhert only knew about half of his batchmates. Despite that, it’s still one
          of his most valued friendship groups and the “best thing” he got out of going through YC.
        </p>
        <p>
          “You’ll see them ten years later and everyone has found a way to become successful. Everyone, without exception, is incredibly
          successful,” he says. The people he saw ten years ago as a bunch of misfits, have now all, in some way, found success.
        </p>
        <p>
          Genetic engineering focused Ginkgo Bioworks and quantum computing company Rigetti Computing have both gone public. Zachariah
          Reitano, who went through YC with a platform called Shout, went on to found healthcare company Ro. The list goes on.
        </p>
        <p>
          “Here’s the secret: everyone starting a startup for the first time is scared, and everyone feels like a bit of an imposter,”<a
            href="https://blog.samaltman.com/startups-role-models-risk-and-y-combinator"
            class="a"
            target="_blank"
            >Altman wrote in 2014</a
          >. “Even the most successful founders doubt themselves and their startups many times in the early days. But founders improve very
          quickly.”
        </p>
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col>
        <TimelineChart
          v-if="!isMobile()"
          :class="isMobile() ? 'mobile' : 'desktop'"
        />
        <TimelinePieChart v-else />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="5"
      ></v-col>
      <v-col
        cols="12"
        md="7"
      >
        <p>
          And YC instils them with the confidence that they are going to succeed. Founders in the programme are told repeatedly that they
          are the 1% of the 1%. It is now expected that around six companies from each batch will become a unicorn.
        </p>
        <p>
          Sometimes this is helpful, and sometimes it puts such pressure on founders that if they don’t succeed, in the traditional sense,
          they feel immensely ashamed. More than 5,000 startups have now gone through the programme, and hundreds of these are now inactive,
          but no one wants to talk about it – not even the founders themselves. What if you want to start another company or even try going
          through Y Combinator again?
        </p>
        <p>
          <span class="s3">Startupland is a hard place</span>, and while having the backing of a programme like YC is a big boost, it’s
          still incredibly difficult to make it.
        </p>
        <p class="s6">
          Lauren Kay co-founded Dating Ring, an online matchmaking service, and was part of YC’s W14 batch. At the time, her life was
          “playing out like a movie”, she wrote in a 2021
          <a
            href="https://ehandbook.com/my-startup-failed-six-years-ago-ive-been-hiding-from-my-shame-ever-since-921f2c60226e?source=user_profile_page---------1-------------cbce658ca055----------------------"
            class="s5"
            target="_blank"
            >Medium post</a
          >
          about how her startup failed, and how she’d been hiding her shame ever since.
        </p>
        <p>
          She and her co-founder Emma Tessler appeared in the <i>New York Times </i>style section, investors were reaching out to them and
          revenue was increasing. She and Tessler even appeared on the second season of the podcast “Startup”, hosted by Alex Blumberg and
          Lisa Chow, and drew in millions of listeners.
        </p>
        <p>
          But when things went south and they weren’t able to gain enough users, set up a successful app or, crucially, fundraise enough
          money, Kay realised someone needed to go and that someone was her.
        </p>
        <h2>The 90% club</h2>
        <p>
          “I had been celebrated as a gifted young founder,” Kay writes. “But now I was convinced I had never actually been intelligent. I
          was just hard-working and – up until then – good at faking my way through life.”
        </p>
        <p>She had felt too ashamed to turn to the YC community when her startup failed, instead becoming a top tutor and an author.</p>
        <p>
          “I’d had the chance to foster relationships with people who now ran billion-dollar companies, but I’d squandered it, convinced
          that my own gamble would pay off”, she writes.
        </p>
        <p>Kay also felt she would be disappointing other female founders.</p>
        <p>
          “As one of the few female founders in a male-dominated industry, I felt like I was letting female founders down everywhere. At the
          time, Dating Ring was only the second all-female founded company ever. Out of 693 companies.”
        </p>
        <p>
          YC still has a problem with diversity. There have been just 681 companies with at least one female founder that have gone through
          the programme. 675, if you don’t include IK12, YC’s one-off education focused batch that consisted solely of edtech companies.
          That means just 13% of YC companies have had a female founder.
        </p>
        <p>
          Out of 16 current partners, just one of them is a woman, Diana Hu, who went through YC in 2017 with augmented reality company
          Escher Reality.
        </p>
        <p>
          Six years after she left Dating Ring, Kay worked up the courage to post on Bookface – YC’s internal social media platform – and
          asked if there were any other founders who’d moved into non-founder roles. She discovered she was far from alone.
        </p>
        <p>
          “It seems like every week there’s a new Coinbase, a new YC company valued at billions of dollars,” she writes. “But the news makes
          these successes appear much more common than they actually are. In reality, closer to 90% of startups fail. We just don’t hear
          about the failures nearly as often, and we fall victim to survivorship bias.”
        </p>
        <h2>Destined for success</h2>
        <p>
          But for those whose startups don’t work out, that doesn’t mean the end of the road. One of the biggest advantages of the programme
          from the start has been the network of people who go through the accelerator with you, and now is the extensive alumni network
          too.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div
          data-v-3dc76b6d=""
          class="v-container v-container--fluid v-locale--is-ltr full-width-quote"
          index="1"
        >
          <div
            data-v-3dc76b6d=""
            class="v-row"
          >
            <div
              data-v-3dc76b6d=""
              class="v-col d-none d-md-flex v-col-2 offset-0 d-flex justify-right v-col-md-2"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-start fill-height"
              ></div>
            </div>
            <div
              data-v-3dc76b6d=""
              class="v-col v-col-12 offset-0 text-center quote-text-container v-col-md-8 offset-md-0"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-text text-left quote-text-spaced"
              >
                We’re in this cohort of people doing amazing things. We should probably be doing something amazing as well, or maybe we are
                already doing something amazing
              </div>
            </div>
            <div
              data-v-3dc76b6d=""
              class="v-col d-none d-md-flex v-col-2 d-flex justify-end v-col-md-2"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-end"
              ></div>
            </div>
          </div>
          <div
            data-v-3dc76b6d=""
            class="v-row mt-0"
          >
            <div
              data-v-3dc76b6d=""
              class="v-col v-col-12 offset-0 pt-0 pt-md-4 v-col-md-4 offset-md-3 font-semibold"
            >
              <p
                data-v-3dc76b6d=""
                class="quote-credit"
              >
                Alisha Fredriksson, Seabound (W22)
              </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="5"
      ></v-col>
      <v-col
        cols="12"
        md="7"
      >
        <p>
          Most YC founders find something interesting to do next. Whether they head to Bookface and look for a job at another startup, go on
          to become a founder again, or do something entirely
        </p>
        <p>
          different, they now have in-built access to some of the world’s most ambitious and smart people by being part of this network.
        </p>
        <p>
          “Everyone, no matter what, found a way. That’s amazing. Despite the odds being very bad,” Lhert says. “I sort of failed, but I
          found a way in the end, and I think everyone else did as well.”
        </p>
        <p>
          Did YC make them into successful people? Did they select people destined for success? It seems like, somehow, the team has always
          known what to look for.
        </p>
        <p>
          “The selection process does mean that they’re getting the crème de la crème now, and even back then they managed to see the types
          of people who were going to be successful at the start,” he says. “They knew what to look for even early on.”
        </p>
        <p>
          One of the biggest characteristics the founders had in common then, Lhert says, was kindness. People used to joke that you could
          get to Series A just by selling to your batchmates. And while there is some truth to that, he sees it more as an advantage of the
          network: an extensive network of founders who default to optimism and charitability, wanting to pay it forward because of what
          they received from YC.
        </p>
        <p class="s1">
          “Back then, I never envisioned that the people we funded would grow into this community of thousands of YC alumni, but I always
          tried to create an asshole-free culture,” Jessica Livingston said at the
          <a
            href="https://www.ycombinator.com/blog/jessica-livingston-shares-9-things-she-learned-from-founding-yc/"
            class="a"
            target="_blank"
            >Female Founders Conference</a
          >
          in 2018.
        </p>
        <p>
          While the three technical co-founders would judge applicants on their hard skills, she would look at the qualities they couldn’t
          see. Were they earnest? Determined? Flexible-minded?
        </p>
        <p>
          “I’ve never met a group of people where the well has not been poisoned by negative people,” Lhert says. “And somehow it’s just an
          organisation that attracts people who are optimistic and positive and kind. The kindness and the charitable nature of a lot of the
          people involved in YC has always astonished me.” This is also something he thinks was instilled by Graham from the start.
        </p>
        <p class="s6">
          There is even a
          <a
            href="https://www.pioneerfund.vc/"
            target="_blank"
            >VC fund</a
          >
          of almost 500 YC alumni who solely invest in and support startups that have been through the accelerator.
        </p>
        <p>
          The other trait they all shared was a strong underdog mentality. Getting into YC felt like finally being seen for who they were,
          an ambitious, scrappy, determined group.
        </p>
        <p>
          “Everyone in our batch had a chip on their shoulder. It felt like something to prove,” he says. Now that more than 400 startups go
          through the programme a year, while it is still extremely competitive, there is less exclusivity than there was, and getting
          accepted isn’t the automatic seal of approval it used to be, for founders or VCs.
        </p>
        <p>
          “The selection process is so rigorous and so hard that it’s not fair anymore. There’s people who probably deserve to be in YC, but
          there’s just not enough space,” Lhert says. And he believes that overflow has changed what YC is.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="mb-4 w-md-75 mx-auto">
          <!--          <video-->
          <!--            class="w-100"-->
          <!--            autoplay-->
          <!--            loop-->
          <!--            muted-->
          <!--          >-->
          <!--            <source-->
          <!--              type="video/mp4"-->
          <!--              :src="Spot02"-->
          <!--            />-->
          <!--          </video>-->
          <div style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              src="https://player.vimeo.com/video/1064604683?badge=0&amp;title=0&amp;background=1&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
              title="spot02"
            ></iframe>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="5"
      ></v-col>
      <v-col
        cols="12"
        md="7"
      >
        <p>
          “YC itself is becoming more of an institution and a structure, and they’re trying to make a repeatable process here and find the
          things that work,” he continues. “It’s less about this organic family thing [and more about] codifying some of the processes and
          scaling them up.”
        </p>
        <p>
          While it used to be the founders who got in that had a chip on their shoulder and something to prove, now it’s those that don’t
          make it who feel they have to prove they can still be successful, even without YC’s backing.
        </p>
        <h2>2015-2020: The institutionalisation of YC</h2>
        <p>
          Altman remained president of Y Combinator until 2019, and under his leadership it became an institutional force. Each batch grew
          to at least 100 companies, and during the covid-19 pandemic this grew to nearly 400 at its peak.
        </p>
        <p>
          In 2015, YC invested in its 1,000th startup and launched YC Continuity, a growth-stage fund designed to support all the companies
          now in the scale up stage. Ali Rowghani, former chief operating officer at, as it then was, Twitter, came on board as a full-time
          partner and managing director of the fund, which was set up to support companies after Demo Day.
        </p>
        <p class="s6">
          YC ended this in 2023 when Garry Tan joined as president and shifted focus back to earlier stage investments. In a
          <a
            href="https://www.ycombinator.com/blog/changes-at-yc"
            class="s5"
            target="_blank"
            >blog post</a
          >, Tan wrote that it was becoming a “distraction” from the core mission to spend time focusing on late-stage funding.
        </p>
        <p>
          2015 was also the year Altman co-founded OpenAI and it became the first batch to be affiliated with YC Research. He split his time
          across both for several years, but when OpenAI announced it was going to launch a for-profit subsidiary in 2019, Altman had to
          choose, and so he left and was succeeded as YC president by Geoff Ralstan.
        </p>
        <p>
          YC made the move to San Francisco that year ahead of the pandemic, which forced it into an all-remote model. Luckily, by this time
          YC had established more of a global footprint. In 2016, YC made an effort to attract founders from across the world. It made a big
          push in its global outreach and travelled to 11 countries, including Nigeria, Portugal, Germany, Russia, Israel and India, to
          engage with founders and expand its reach beyond Silicon Valley.
        </p>
        <h2>2020-2025: Scaling and the future</h2>
        <p>
          2021 and 2022 saw YC’s two largest batches: S21 had 391 startups while W22 had 399. This led to concerns about dilution. While
          investment increased and the brand name of YC was as powerful as ever, the hacker ethos from the early days was lost.
        </p>
        <p>
          Instead of meeting with partners every day or even every week, current founders meet with their partners once every other week and
          don’t interact with half of their batch. But the community still plays a huge role, even if it has shifted over the last 20 years.
        </p>
        <p>
          “I mean the people that you meet, the other founders who are going through this stage of the journey, the hardest part, zero to
          one, being surrounded by the right community is paramount,” Don Muir, who co-founded cash-management app Arc, says.
        </p>
        <p>
          Muir went through YC in W22 and was part of a mostly virtual batch. Having already raised $11m, Arc was part of the 55% of the
          batch that had already raised money, marking a shift of founders applying to be part of YC at increasingly later stages of their
          company journey.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <!-- quote -->
        <div
          data-v-3dc76b6d=""
          class="v-container v-container--fluid v-locale--is-ltr full-width-quote"
          index="1"
        >
          <div
            data-v-3dc76b6d=""
            class="v-row"
          >
            <div
              data-v-3dc76b6d=""
              class="v-col d-none d-md-flex v-col-2 offset-0 d-flex justify-right v-col-md-2"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-start fill-height"
              ></div>
            </div>
            <div
              data-v-3dc76b6d=""
              class="v-col v-col-12 offset-0 text-center quote-text-container v-col-md-8 offset-md-0"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-text text-left quote-text-spaced"
              >
                In my mind it was like you start a company, you join YC and then you’re set. That’s not actually the case, but we applied to
                YC because it was the place to get pre-seed funding
              </div>
            </div>
            <div
              data-v-3dc76b6d=""
              class="v-col d-none d-md-flex v-col-2 d-flex justify-end v-col-md-2"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-end"
              ></div>
            </div>
          </div>
          <div
            data-v-3dc76b6d=""
            class="v-row mt-0"
          >
            <div
              data-v-3dc76b6d=""
              class="v-col v-col-12 offset-0 pt-0 pt-md-4 v-col-md-4 offset-md-3 font-semibold"
            >
              <p
                data-v-3dc76b6d=""
                class="quote-credit"
              >
                Akhil Gupta, Fresco (F24)
              </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="5"
      ></v-col>
      <v-col
        cols="12"
        md="7"
      >
        <p>
          Even though the programme had gone virtual, people like Muir hosted events for the batch and tried to spend as much time together
          as possible, learning from each other and figuring out how to be founders.
        </p>
        <p>
          “Entrepreneurship can be isolating,” he tells <i>Digital Frontier</i>, explaining that “to be surrounded by others who are
          literally going through the exact same thing”, who “end up thinking the same way”, is instrumental. “You’re bonded by these
          shared, traumatic experiences of trying to find product-market fit and trying to build something from scratch,” he continues.
          “Having that community, surrounding yourself with a supportive community, is invaluable.”
        </p>
        <p>
          Hearing from alumni and later stage founders like Airbnb founder Brian Chesky and Parker Conrad, founder of Rippling, also showed
          Muir “what was in the art of the possible” if he followed the YC playbook.
        </p>
        <p class="s6">
          Alisha Fredriksson joined the batch remotely from Singapore with her startup, Seabound. She hadn’t thought about YC as an option
          because she thought it was a software, tech- focused startup programme and it didn’t feel relevant given they were building a
          climate- focused hardware company. But a friend who had gone through it recommended it, and she saw climate startups on YC’s “<a
            href="https://www.ycombinator.com/rfs"
            class="s5"
            target="_blank"
            >requests for startups page</a
          >”, so she saw it as an invitation to apply.
        </p>
        <p>
          Being remote meant she missed out on forming relationships with other members of the batch beyond the climate-focused subgroup,
          but she still felt the positive pressure of being surrounded by people who were also building.
        </p>
        <p>
          “We’re in this cohort of people doing amazing things. We should probably be doing something amazing as well, or maybe we are
          already doing something amazing,” she says.
        </p>
        <p>And having positive feedback from partners helped too.</p>
        <p>
          “Just getting encouragement from a YC partner is strangely super validating, because these are smart people, right? And if they
          believe in you, then you know that helps to fuel you to keep going,” she says. “It’s a small little psychological trick, but I
          found it quite powerful.”
        </p>
        <p>
          Muir and Fredriksson were part of the first batch where the investment structure increased to $500,000 for 7% equity, having
          previously increased to $150,000 for 7% in 2018.
        </p>
        <p>
          Because those hadn’t been the terms they applied with, they were given the option of taking the higher investment offer, and it
          didn’t make sense not to.
        </p>
        <p>
          “I think it kind of emboldened us a little bit to act faster,” Fredriksson says. Not having to spend time fundraising left much
          more time for building.
        </p>
        <h2>Crossing the pond</h2>
        <p>
          For Tranch, a payments company that started in the UK and had already raised money on home turf, going through the programme was
          “less about capital and more about access”.
        </p>
        <p>
          “Y Combinator enabled us to essentially expand in the US with the Y Combinator brand,” co- founder Philip Kelvin tells
          <i>Digital Frontier</i>. “That gave businesses confidence to buy from us in the US when originally the founders were both
          UK-based.”
        </p>
        <p>
          The payments system in the UK had given the co-founders the experience and knowledge of how to run a B2B fintech business, but the
          US was the largest, toughest market to crack.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!--Spot04-->
        <div class="mb-4 w-md-75 mx-auto">
          <!--          <video-->
          <!--            class="w-100"-->
          <!--            autoplay-->
          <!--            loop-->
          <!--            muted-->
          <!--          >-->
          <!--            <source-->
          <!--              type="video/mp4"-->
          <!--              :src="Spot04"-->
          <!--            />-->
          <!--          </video>-->
          <div style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              src="https://player.vimeo.com/video/1064604723?badge=0&amp;title=0&amp;background=1&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
              title="spot04"
            ></iframe>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="5"
      ></v-col>
      <v-col
        cols="12"
        md="7"
      >
        <p>
          “As Y Combinator will say, they’re not a school,” Kelvin says. “They’re there to support and enable you, but they’re not there to
          tell you how to do things. So for us it was how we could leverage off the YC network and brand to enable us to expand.”
        </p>
        <p>
          And it worked. Three and a half years later, Tranch was acquired by Elite, a financial management service for law practices, in
          January 2025.
        </p>
        <p>
          “That wasn’t our goal by any means when we started the company,” Kelvin says. “But I think YC enabled us to create very much a
          growth mindset, a profitable growth mindset that then enabled us to build a company where we built a product that people wanted.”
        </p>
        <p>
          By following YC’s mantra, “build a product that people want”, they ultimately built a company that people wanted to acquire as
          well.
        </p>
        <p>
          And the network has been helpful for Kelvin too. He’s invested in his batchmates’ companies, and says “the YC London WhatsApp
          group is one of the most powerful tools in London”.
        </p>
        <h2>Spring and fall</h2>
        <p>
          Batch sizes have scaled back a bit in the past few years since Garry Tan took over as president, but as of this year, there will
          be four batches a year, rather than two. Last year a fall batch was introduced and this year a spring batch is rounding out the
          seasons.
        </p>
        <p>
          Now, about 80% of the companies are AI-related – lots of companies are working on the same ideas and there is a lot of overlap.
          There is also a lot of pivoting, with some founders explicitly being told the partners believe in them as founders, but that their
          idea is bad.
        </p>
        <p>
          One of the drawbacks to building in an arena full of other smart, ambitious people is that they could very easily pivot into your
          space and become your competitor.
        </p>
        <p>
          “You can feel pressure to be exceptional,” Akhil Gupta, co-founder of Fresco, tells <i>Digital Frontier</i>. He and his co-founder
          were part of the F24 batch, and luckily the construction automation startup space wasn’t too busy. And he worked together with
          other batch mates – whether that was borrowing cold email templates or hearing about their business tactics.
        </p>
        <p>
          The group partners also turned out to be useful in a way he didn’t expect. Given how many startups are going through YC now, it is
          a common complaint that the partners are disconnected from the companies they’re mentoring, or that they’ll go to office hours and
          meet with a different partner who will have no idea what they’re working on.
        </p>
        <p>
          But they helped the Fresco founders connect to different people and think more objectively about where the business was going.
        </p>
        <p>
          “The ‘worst investor’ is very common, but the ‘best investor’ never happens,” he says. “And it turns out these guys were like the
          best investors.”
        </p>
        <p>Gupta had applied to all the startup accelerator programmes, but YC was the clear frontrunner.</p>
        <p>
          “In my mind it was like you start a company, you join YC and then you’re set,” he says. “That’s not actually the case, but we
          applied to YC because it was <i>the </i>place to get pre-seed funding.”
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <!-- quote -->
        <div
          data-v-3dc76b6d=""
          class="v-container v-container--fluid v-locale--is-ltr full-width-quote"
          index="1"
        >
          <div
            data-v-3dc76b6d=""
            class="v-row"
          >
            <div
              data-v-3dc76b6d=""
              class="v-col d-none d-md-flex v-col-2 offset-0 d-flex justify-right v-col-md-2"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-start fill-height"
              ></div>
            </div>
            <div
              data-v-3dc76b6d=""
              class="v-col v-col-12 offset-0 text-center quote-text-container v-col-md-8 offset-md-0"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-text text-left quote-text-spaced"
              >
                The great thing about being a YC company is that advice becomes free. You can just go to one of the greatest people who has
                built successful startups before within YC
              </div>
            </div>
            <div
              data-v-3dc76b6d=""
              class="v-col d-none d-md-flex v-col-2 d-flex justify-end v-col-md-2"
            >
              <div
                data-v-3dc76b6d=""
                class="quote-end"
              ></div>
            </div>
          </div>
          <div
            data-v-3dc76b6d=""
            class="v-row mt-0"
          >
            <div
              data-v-3dc76b6d=""
              class="v-col v-col-12 offset-0 pt-0 pt-md-4 v-col-md-4 offset-md-3 font-semibold"
            >
              <p
                data-v-3dc76b6d=""
                class="quote-credit"
              >
                James He, Artificial Societies (W25)
              </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="5"
      ></v-col>
      <v-col
        cols="12"
        md="7"
      >
        <p>
          Having that initial investment meant they “felt very free”, he says, and having the YC brand made it easy to go on to raise a seed
          round immediately after their Demo Day.
        </p>
        <p>
          “We are in a really good position right now with our company and I think with any other accelerator, any other source of funding,
          we wouldn’t be here,” he says.
        </p>
        <p>
          For James He, the decision wasn’t so obvious. Artificial Societies had already raised funds before going through the programme and
          he and his co-founder Patrick Sharpe calculated that it would effectively cost $600,000 to do the programme because of the equity
          it would give away.
        </p>
        <p>
          Rather than being a draw, the investment and the equity trade-off could’ve been the reason they didn’t go through the programme.
          But the network, and the brand name, proved irresistible.
        </p>
        <p>
          “The great thing about being a YC company is that advice becomes free. You can just go to one of the greatest people who has built
          successful startups before within YC,” He says. “Because you are a YC startup, a lot of people from outside YC are also pretty
          happy to give you advice as well.”
        </p>
        <p>
          When we speak he says he’s finding it worthwhile, but the answer at week four would have been different, he says. He had a
          realisation that Y Combinator is exactly the same as the University of Cambridge, where he went for undergraduate, in that “they
          put a lot of people together and create a social norm and use that norm to push it towards a certain direction”.
        </p>
        <p>
          In Cambridge, that was towards grades and research. In YC, it’s building for revenue. For some, that group pressure to succeed
          works. For others, it doesn’t.
        </p>
        <h2>2045</h2>
        <p>
          Over the past 20 years, YC has funded more than 5,000 startups and worked with more than 7,000 founders. It has seen 16 IPOs, more
          than 100 unicorns and more than 400 companies valued at $100m.
        </p>
        <p class="s1">
          It has transformed from a small experiment in startup funding – a summer programme for ambitious college students – to one of the
          most influential institutions in tech. In a full circle moment for YC, it’s even launching
          <a
            href="https://www.ycombinator.com/blog/summer-fellows"
            class="a"
            target="_blank"
            >Summer Fellows Grants</a
          >
          this summer for college students working on technical products.
        </p>
        <p>
          It has evolved from the early days in Cambridge in Graham and Livingston’s house to having a dominant presence in San Francisco,
          and it has done that by constantly staying at the forefront – scaling in size, adapting to market shifts and embracing new
          technologies.
        </p>
        <p>
          But at its core, the formula remains simple: find ambitious founders, give them the money to get started, some support and
          guidance to push them forward and a network that will get them over the line and support them in the future.
        </p>
        <p>
          As the tech landscape enters its next chapter, marked by AI breakthroughs, shifting venture capital trends and an increasingly
          global startup ecosystem, YC’s role as a launchpad for the world’s next great companies seems far from over.
        </p>
        <p>
          If the past two decades are any indication, the startups of tomorrow will likely have their roots in a YC batch today. As Muir
          puts it: “I do believe that YC will continue to thrive for the next 20 years. In fact, it seems that they’re just getting
          started.”
        </p>

        <p class="s4"><i>Additional reporting by Tasmin Lockwood, Jennifer Sieg, Isabelle Castro and Jonathan Stein.</i></p>
        <p class="s10">
          <i>
            If you want to read more about startups and founders,
            <a
              href="https://digitalfrontier.com/newsletter-preferences/anatomy-of-a-founder"
              target="_blank"
              >subscribe</a
            >
            to the Anatomy of a Founder newsletter.
          </i>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang="scss">
  .full-width-quote {
    .quote-text-container {
      position: relative;
      z-index: 1;
      .quote-text {
        font-size: 1.9em;
        padding-top: 0;
        line-height: 1.2em;
        font-family: "DepartureMonoRegular", sans-serif !important;
        font-weight: normal;
        @media only screen and (max-width: 599px) {
          margin-top: 24px;
        }
      }
      .quote-text-spaced {
        margin: 0 24px;
      }
      @media only screen and (max-width: 599px) {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          background-image: url(@/assets/quote.svg);
          transform: rotate(180deg);
          background-position: bottom right;
          background-repeat: no-repeat;
          background-size: 80%;
        }
      }
    }
    @media only screen and (max-width: 599px) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  .quote-start {
    background-image: url(@/assets/quote.svg);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
  }
  .quote-end {
    background-image: url(@/assets/quote.svg);
    transform: rotate(180deg);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
  }
  .article-page .quote-credit {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .show-link:hover {
    cursor: pointer;
  }
</style>
