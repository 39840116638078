
export const trackingService = {
  hasConsentForC0002,
  isGtagEnabled,
  checkConsentChange,
  pushUserIdToG4A,
  trackEvent,
  setUserProperties,
  clearUserProperties
};

function hasConsentForC0002() {
  if (typeof window.OnetrustActiveGroups === "string") {
    return window.OnetrustActiveGroups.includes('C0002');
  }
  return false
}

function isGtagEnabled() {
  return typeof window.gtag === 'function';
}

let consentCheckInterval; // Store the interval ID

// Function to detect consent changes
function checkConsentChange (userId, subscriptionStatus = null, subscriptionType = null) {

  // 🛑 Stop the interval if already running
  if (consentCheckInterval) {
    clearInterval(consentCheckInterval);
    console.log('🛑 Stopped consent check interval');
  }

  consentCheckInterval = setInterval(() => {
    console.log('🔄 Checking cookie consent...');
    if (hasConsentForC0002()) {
      if (userId) {
        pushUserIdToG4A(userId, subscriptionStatus, subscriptionType);
      }
      setGoogleSignalsConsent(true)
    }
    else {
      clearUserProperties()
      setGoogleSignalsConsent(false)
    }
  }, 5000); // Check every 5 seconds
}

function setGoogleSignalsConsent(hasAccepted) {
  if (isGtagEnabled()) {
    if (hasAccepted) {
      console.log(`✅ Signals Consented`);
    }
    else {
      console.log(`🛑 Signals Not Consented`);
    }
    gtag('consent', 'update', {
      'ads_data_redaction': !hasAccepted // Invert: true = disabled, false = enabled
    });
  }
}

function pushUserIdToG4A (userId, subscriptionStatus = null, subscriptionType = null) {

  if (hasConsentForC0002()) {

    console.log(`✅ User ID ${userId} pushed to G4A`);

    if (isGtagEnabled()) {

      // set the user_id for the current session
      gtag('config', 'G-HFCG3JLMRB', {
        'user_id': userId
      });

      setUserProperties(userId, subscriptionStatus, subscriptionType)
      setGoogleSignalsConsent(true)

    }

    // 🛑 Stop the interval since consent is now granted
    if (consentCheckInterval) {
      clearInterval(consentCheckInterval);
      console.log('🛑 Stopped consent check interval');
    }
  } else {
    console.log('❌ User has NOT given C0002 consent. Not pushing user ID.');
  }
}

function setUserProperties(userId, subscriptionStatus = null, subscriptionType = null) {

  if (isGtagEnabled()) {

    if (userId) {
      gtag('set', 'user_properties', {
        user_id: userId
      });
    }

    gtag('set', 'user_properties', {
      subscription_status: subscriptionStatus,
      subscription_type: subscriptionType
    });

  }
}

function clearUserProperties() {
  if (isGtagEnabled()) {

    gtag('set', 'user_properties', {
      user_id: null,
      subscription_status: null,
      subscription_type: null
    });

  }
}

function trackEvent (event) {
  if (isGtagEnabled()) {
    gtag('event', event);
  }
}
